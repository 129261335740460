import { Center, ChakraProps, Spinner } from '@chakra-ui/react';

function Loading(props: ChakraProps) {
  return (
    <Center {...props}>
      <Spinner />
    </Center>
  );
}

export default Loading;
