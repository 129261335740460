import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { listChains } from "configs/chain";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useAddWhitelist from "../hooks/useAddWhitelist";

interface IProps {
  isOpen: boolean;
  currentId: any;
  onClose: () => void;
}
export default function ModalAddWhitelist({
  isOpen,
  currentId,
  onClose,
}: IProps) {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({});
  const { isLoading, mutate } = useAddWhitelist({
    onClose,
    reset,
    id: currentId?._id,
  });

  useEffect(() => {
    reset({
      chainId: currentId?.chainId,
      address: currentId?.address,
      screen: currentId?.screen,
      description: currentId?.description,
    });
  }, [currentId, reset]);

  const onSubmit = (values: any) => {
    mutate(values);
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a whitelist</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ModalBody pb={6}>
            <Controller
              name="chainId"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FormControl isInvalid={Boolean(error?.message)} isRequired>
                  <FormLabel>Chain</FormLabel>
                  <Select placeholder="Select Chain" {...rest}>
                    {listChains.map((chain) => (
                      <option key={chain.value} value={chain.value}>
                        {chain.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    <>{error?.message}</>
                  </FormErrorMessage>
                </FormControl>
              )}
              rules={{
                required: {
                  value: true,
                  message: "This is required",
                },
              }}
            />

            <FormControl mt={4} isInvalid={Boolean(errors.address)} isRequired>
              <FormLabel>Address</FormLabel>
              <Input
                placeholder="Address"
                {...register("address", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.address?.message}</>
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={Boolean(errors.description)}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                {...register("description", {})}
              />
              <FormErrorMessage>
                <>{errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              colorScheme="blue"
              isLoading={isLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
