import { ChainIds } from "configs/chain";

import { get, instance } from "utils/BaseRequest";

export interface GetTokenInfoRequestParams {
  pageIndex: number;
  pageSize: number;
  skip?: number;
  chainId?: string;
  keyword?: string;
  status?: number[];
}

export interface UpdateTokenInfoParams {
  chainId: ChainIds;
  address: string;
  owner: string;
  website: string;
  telegram: string;
  twitter: string;
  discord: string;
  github: string;
  description: string;
  status: number;
}

export const getTokenInfoRequest = async (
  params: GetTokenInfoRequestParams
) => {
  const result = await get("/token-info-request", params);
  return result;
};

export const getTotalTokenInfoRequest = async () => {
  return await instance.get("/manager/token-info-request/total-request");
};

export const updateTokenInfoRequest = async (
  id: string,
  params: UpdateTokenInfoParams
) => await instance.patch(`/manager/token-info-request/${id}`, params);
