import queryKey from 'configs/queryKey';
import { useQuery } from '@tanstack/react-query';
import { getTokenInfo, GetTokenInfoParams } from 'utils/apiService/TokenInfo';

function useTokenInfo(params: GetTokenInfoParams) {
  return useQuery([queryKey.TOKEN_INFO, params], () => getTokenInfo(params), {
    enabled: true,
    staleTime: 60 * 1000,
  });
}

export default useTokenInfo;
