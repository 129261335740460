import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  useDisclosure,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Paginate from "components/Pagination";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";
import useDebounce from "shared/hooks/useDebouce";

import {
  getListDex,
  delDex,
  addDex,
  updateDex,
  getListChain,
} from "utils/apiService";
import DexTable from "./components/DexTable";

const columns = [
  {
    Header: "ChainId",
    accessor: "chainId",
  },
  {
    Header: "Factory",
    accessor: "factory",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Avatar",
    accessor: "avatar",
  },
  {
    Header: "Priority",
    accessor: "priority",
  },
  {
    Header: "Status",
    accessor: "status",
  },

  {
    Header: "Action",
    accessor: "_id",
  },
];

export default function ManageDex() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const cancelRefAdd = React.useRef();

  const [currentId, setCurrentId] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(1);
  const [currentDex, setCurrentDex] = React.useState({
    chainId: "",
    factory: "",
    name: "",
    avatar: "",
    priority: 0,
    status: 1,
    slug: "",
  });
  const [chainId, setChainId] = React.useState<string>();
  const [keyword, setKeyword] = React.useState<string>();

  const debounceValue = useDebounce(keyword, 1000);

  const { data: dexes } = useQuery(
    ["DEX_LIST", pageIndex, chainId, debounceValue],
    () =>
      getListDex({ chain: chainId, page: pageIndex, keyword: debounceValue })
  );

  const { data: chains } = useQuery(["CHAIN_LIST"], () =>
    getListChain(1, 1000)
  );

  const onClickDel = (id: string) => {
    onOpen();
    setCurrentId(id);
  };

  const onConfirmDel = async () => {
    try {
      if (currentId) {
        await delDex(currentId);
        toast({
          title: "Dex.",
          description: "Dex deleted.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries(["DEX_LIST"]);
      }
    } catch {
      toast({
        title: "Dex.",
        description: "Dex can't deleted.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentId(null);
    onClose();
  };

  const onClickUpdate = (dex: any) => {
    onOpenAdd();
    setCurrentDex(dex);
    setCurrentId(dex._id);
  };

  const onClickOpenAdd = () => {
    onOpenAdd();
    setCurrentDex({
      chainId: "",
      factory: "",
      name: "",
      avatar: "",
      slug: "",
      priority: 0,
      status: 1,
    });
    setCurrentId(null);
  };

  const onConfirmAddOrUpdate = async () => {
    try {
      if (currentId) {
        await updateDex(currentId, currentDex);
        toast({
          title: "Dex.",
          description: "Dex updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        await addDex(currentDex);
        toast({
          title: "Dex.",
          description: "Dex added.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      await queryClient.invalidateQueries(["DEX_LIST"]);
      setCurrentId(null);
      setCurrentDex({
        chainId: "",
        factory: "",
        name: "",
        slug: "",
        avatar: "",
        priority: 0,
        status: 1,
      });
    } catch {
      toast({
        title: "Dex.",
        description: "Dex can't updated.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    onCloseAdd();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onConfirmDel} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenAdd}
        leastDestructiveRef={cancelRefAdd}
        onClose={onCloseAdd}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {currentId ? "Update" : "Add"} Dex
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb="8px">Name</Text>
              <Input
                placeholder="Name"
                size="md"
                mb="8px"
                value={currentDex?.name || ""}
                onChange={(e) =>
                  setCurrentDex({ ...currentDex, name: e.target.value })
                }
              />

              <Text mb="8px">ChainId</Text>
              <Select
                icon={<MdArrowDropDown />}
                placeholder="Select chain"
                bg={"whiteAlpha.200"}
                mr={"2rem"}
                value={currentDex?.chainId}
                onChange={(e) => {
                  setCurrentDex({ ...currentDex, chainId: e.target.value });
                }}
              >
                {chains?.data?.length &&
                  chains?.data?.map((chain: any) => {
                    return (
                      <option key={chain.id} value={chain.chainId}>
                        {chain.name}
                      </option>
                    );
                  })}
              </Select>

              <Text mb="8px">Factory</Text>
              <Input
                placeholder="Factory"
                size="md"
                mb="8px"
                value={currentDex?.factory || ""}
                onChange={(e) =>
                  setCurrentDex({ ...currentDex, factory: e.target.value })
                }
              />

              <Text mb="8px">Avatar</Text>
              <Input
                placeholder="Avatar"
                size="md"
                mb="8px"
                value={currentDex?.avatar || ""}
                onChange={(e) =>
                  setCurrentDex({ ...currentDex, avatar: e.target.value })
                }
              />

              <Text mb="8px">Priority</Text>
              <Input
                placeholder="Priority"
                size="md"
                mb="8px"
                type={"number"}
                value={currentDex?.priority || 0}
                onChange={(e) =>
                  setCurrentDex({
                    ...currentDex,
                    priority: Number(e.target.value),
                  })
                }
              />

              <Text mb="8px">Slug</Text>
              <Input
                placeholder="Slug"
                size="md"
                mb="8px"
                value={
                  currentDex?.slug ||
                  String(currentDex.name).split(" ").join("-").toLowerCase()
                }
                onChange={(e) =>
                  setCurrentDex({
                    ...currentDex,
                    slug: e.target.value,
                  })
                }
              />

              <Text mb="8px">Status</Text>
              <Select
                placeholder="Select option"
                value={currentDex?.status}
                onChange={(e) =>
                  setCurrentDex({
                    ...currentDex,
                    status: Number(e.target.value),
                  })
                }
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAdd}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={onConfirmAddOrUpdate} ml={3}>
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <DexTable
          columnsData={columns}
          tableData={dexes?.data || []}
          onClickDel={onClickDel}
          onClickUpdate={onClickUpdate}
          onOpenAdd={onClickOpenAdd}
          chains={chains}
          onChangeChain={(v: string) => {
            setChainId(v);
          }}
          onChangeKeyword={(v: string) => {
            setKeyword(v);
          }}
        />
        <Paginate data={dexes} callback={setPageIndex} />
      </Box>
    </>
  );
}
