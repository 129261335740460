import { getWhitelist, GetWhitelistParams } from 'utils/apiService/Whitelist';
import queryKey from 'configs/queryKey';
import { useQuery } from '@tanstack/react-query';

function useWhitelist({ page, limit, keyword }: GetWhitelistParams) {
  return useQuery(
    [queryKey.WHITE_LIST, page, limit, keyword],
    () => getWhitelist({ page, limit, keyword }),
    {
      enabled: Boolean(page) && Boolean(limit),
      staleTime: 60 * 1000,
    }
  );
}

export default useWhitelist;
