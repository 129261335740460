import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getChainNameFromChainId } from "configs/chain";
import { useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { subAddress } from "utils/helpers";
import ModalAddWhitelist from "./ModalAddWhitelist";
import ModalRemoveWhitelist from "./ModalRemoveWhitelist";

const columns = [
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "ChainId",
    accessor: "chainId",
  },
  {
    Header: "Created At",
    accessor: "createdAt",
  },
  {
    Header: "Action",
    accessor: "_id",
  },
];

interface IProps {
  tableData: any;
}
export default function WhitelistTable({ tableData }: IProps) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();
  const {
    isOpen: isOpenRemove,
    onOpen: onOpenRemove,
    onClose: onCloseRemove,
  } = useDisclosure();
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();

  const [rowObj, setRowObj] = useState(null);

  const tableInstance = useTable(
    {
      columns,
      data: tableData || [],
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("white", "navy.800");

  const handleOpenRemove = (row: any) => {
    setRowObj(row);
    onOpenRemove();
  };

  const handleUpdate = (row: any) => {
    setRowObj(row);
    onOpenUpdate();
  };

  const handleCopy = (address: string | undefined) => {
    if (!address) return;
    navigator.clipboard.writeText(address);
    toast({
      title: "Copy successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <>
      <ModalRemoveWhitelist
        isOpen={isOpenRemove}
        onClose={onCloseRemove}
        currentId={rowObj?._id}
      />
      <ModalAddWhitelist
        isOpen={isOpenUpdate}
        onClose={onCloseUpdate}
        currentId={rowObj}
      />

      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  // pos={{
                  //   base: column?.Header === 'Action' ? 'sticky' : 'unset',
                  // }}
                  // zIndex={{
                  //   base: column?.Header === 'Action' ? 'banner' : 'unset',
                  // }}
                  // right={{
                  //   base: column?.Header === 'Action' ? '0px' : 'unset',
                  // }}
                  bgColor={bgColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody
          {...getTableBodyProps()}
          color={textColor}
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          {page?.map((row: any, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td
                  onClick={() => handleCopy(row?.original?.address)}
                  cursor="copy"
                >
                  {subAddress(row?.original?.address)}
                </Td>
                <Td>{row?.original?.description}</Td>

                <Td>
                  {getChainNameFromChainId(Number(row?.original?.chainId))}
                </Td>
                <Td>
                  {row?.original?.createdAt
                    ? new Date(row?.original?.createdAt).toLocaleDateString()
                    : null}
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    mr="5px"
                    mt="5px"
                    size="sm"
                    onClick={() => handleUpdate(row?.original)}
                  >
                    Update
                  </Button>
                  <Button
                    colorScheme="red"
                    mr="5px"
                    mt="5px"
                    size="sm"
                    onClick={() => handleOpenRemove(row?.original)}
                  >
                    Delete
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}
