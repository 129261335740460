import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import Paginate from "components/Pagination";
import { isEmpty } from "lodash";
import React, { ChangeEvent, useRef } from "react";

import Loading from "components/loading";
import SearchItem from "components/SearchItem";
import {
  addBlacklistPair,
  addWhitelistPair,
  getBlacklistPair,
  getListChain,
  getPairsTrending,
  getWhitelistPair,
  removeBlacklistPair,
  removeWhitelistPair,
  searchPair,
} from "utils/apiService";
import BlackListPairTable from "./components/BlackListPairTable";
import ModalEditRuleCheck from "./components/ModalEditRuleCheck";
import PairTrendingTable from "./components/PairTrendingTable";
import WhiteListPairTable from "./components/WhiteListPairTable";

export default function ManagePairTrending() {
  const toast = useToast();
  const queryClient = useQueryClient();
  const bgSearch = useColorModeValue("white", "white.500");

  const { data: chains, isLoading } = useQuery(["CHAIN_LIST"], () =>
    getListChain(1, 1000)
  );

  const {
    isOpen: isOpenDelBlackList,
    onOpen: onOpenDelBlackList,
    onClose: onCloseDelBlackList,
  } = useDisclosure();

  const {
    isOpen: isOpenDelWhiteList,
    onOpen: onOpenDelWhiteList,
    onClose: onCloseDelWhiteList,
  } = useDisclosure();

  const {
    isOpen: isOpenAddBlackListWhiteList,
    onOpen: onOpenAddBlackListWhiteList,
    onClose: onCloseAddBlackListWhiteList,
  } = useDisclosure();

  const {
    isOpen: isOpenEditRuleCheck,
    onOpen: onOpenEditRuleCheck,
    onClose: onCloseEditRuleCheck,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const cancelRefAdd = React.useRef();

  const [pageIndex, setPageIndex] = React.useState(1);
  const [isAddBlacklist, setIsAddBlacklist] = React.useState(true);

  const [liveTimeData, setLiveTimeData] = React.useState({
    liveTimeFrom: Date.now(),
    liveTimeTo: Date.now() + 1000 * 60 * 60,
    position: 1,
  });

  const liveTimeFromRef = useRef<HTMLInputElement>();
  const liveTimeToRef = useRef<HTMLInputElement>();

  const [search, setSearch] = React.useState(null);
  const [blacklistPageIndex, setBlacklistPageIndex] = React.useState(1);
  const [whitelistPageIndex, setWhitelistPageIndex] = React.useState(1);
  const [currentEditRuleCheckPair, setCurrentEditRuleCheckPair] =
    React.useState<{
      _id: string;
      chainId: string;
      address: string;
      status: number;
      isIgnoreRuleCheck?: boolean;
    }>();

  const [currentBlackWhitePair, setCurrentBlackWhitePair] = React.useState<{
    address: string;
    chainId: string;
  }>(null);

  const [currentBlackListPair, setCurrentBlackListPair] = React.useState<{
    address: string;
    chainId: string;
  }>(null);

  const [currentWhiteListPair, setCurrentWhiteListPair] = React.useState<{
    address: string;
    chainId: string;
    _id?: string;
  }>(null);

  const { data: pairTrendings } = useQuery(
    ["PAIR_TRENDING_LIST", pageIndex],
    () => getPairsTrending(pageIndex)
  );

  const { data: pairBlackList } = useQuery(
    ["BLACKLIST_PAIR", blacklistPageIndex],
    () => getBlacklistPair(blacklistPageIndex)
  );

  const { data: result, isFetching } = useInfiniteQuery<any>(
    ["searchPair", search],
    async ({ pageParam = 1 }) => {
      const data = await searchPair(search, pageParam);
      return data;
    },
    {
      getPreviousPageParam: (firstPage: any) =>
        firstPage?.previousId ?? undefined,
      getNextPageParam: (lastPage: any) => lastPage?.nextId ?? undefined,
    }
  );

  const { data: pairWhiteList } = useQuery(
    ["WHITELIST_PAIR", whitelistPageIndex],
    () => getWhitelistPair(whitelistPageIndex)
  );

  const onClickDelBlackListItem = (pairAddress: string, chainId: string) => {
    onOpenDelBlackList();
    setCurrentBlackListPair({ address: pairAddress, chainId: chainId });
  };

  const onClickDelWhiteListItem = (_id: string) => {
    onOpenDelWhiteList();

    setCurrentWhiteListPair({ _id, address: "", chainId: "" });
  };

  const onCloseSearch = (data: { chainId: string; address: string }) => {
    if (data?.address && data?.chainId) {
      setCurrentBlackWhitePair(data);
    }

    setSearch("");
  };

  const onClickEditRuleCheck = (data: {
    _id: string;
    chainId: string;
    address: string;
    status: number;
    isIgnoreRuleChecker?: boolean;
  }) => {
    onOpenEditRuleCheck();
    setCurrentEditRuleCheckPair(data);
  };

  const onConfirmDelBlacklistItem = async () => {
    try {
      if (currentBlackListPair) {
        const rs = await removeBlacklistPair(currentBlackListPair);

        if (rs.status === 400) {
          throw new Error(rs.message);
        }

        toast({
          title: "Remove blacklist pair",
          description: "Remove blacklist pair successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries([
          "BLACKLIST_PAIR",
          blacklistPageIndex,
        ]);
      }
    } catch {
      toast({
        title: "Remove blacklist pair",
        description: "Remove blacklist pair failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentBlackListPair(null);
    onCloseDelBlackList();
  };

  const toUtcTimestamp = (date: string) => {
    return date + "Z";
  };
  const onConfirmAddBlackWhiteList = async () => {
    try {
      if (isAddBlacklist) {
        const rs = await addBlacklistPair(currentBlackWhitePair);

        if (rs.status === 400) {
          throw new Error(rs.message);
        }

        toast({
          title: "Blacklist pair",
          description: "Add blacklist pair successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries([
          "BLACKLIST_PAIR",
          blacklistPageIndex,
        ]);
      } else {
        const rs = await addWhitelistPair({
          ...currentBlackWhitePair,
          ...liveTimeData,
          liveTimeFrom: new Date(
            toUtcTimestamp(liveTimeFromRef.current.value)
          ).getTime(),
          liveTimeTo: new Date(
            toUtcTimestamp(liveTimeToRef.current.value)
          ).getTime(),
        });

        if (rs.status === 400) {
          throw new Error(rs.message);
        }

        toast({
          title: "Success",
          description: "Add successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries([
          "WHITELIST_PAIR",
          blacklistPageIndex,
        ]);
      }
    } catch {
      toast({
        title: "Pair",
        description: "Add pair failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentBlackWhitePair(null);
    onCloseAddBlackListWhiteList();
  };

  const onChangePosition = (e: any) => {
    setLiveTimeData({
      ...liveTimeData,
      position: Number(e.target.value),
    });
  };

  const onOpenAddBlackList = () => {
    setIsAddBlacklist(true);
    onOpenAddBlackListWhiteList();
  };

  const onOpenAddWhiteList = () => {
    setIsAddBlacklist(false);
    onOpenAddBlackListWhiteList();
  };

  const onConfirmDelWhitelistItem = async () => {
    try {
      if (currentWhiteListPair) {
        await removeWhitelistPair(currentWhiteListPair);
        toast({
          title: "Remove whitelist pair",
          description: "Remove whitelist pair successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries([
          "WHITELIST_PAIR",
          whitelistPageIndex,
        ]);
      }
    } catch {
      toast({
        title: "Remove whitelist pair",
        description: "Remove whitelist pair failed",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentWhiteListPair(null);
    onCloseDelWhiteList();
  };

  return (
    <>
      {currentEditRuleCheckPair && (
        <ModalEditRuleCheck
          isOpen={isOpenEditRuleCheck}
          onClose={onCloseEditRuleCheck}
          currentEditRuleCheckPair={currentEditRuleCheckPair}
        />
      )}

      <AlertDialog
        isOpen={isOpenDelBlackList}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDelBlackList}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDelBlackList}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onConfirmDelBlacklistItem}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenDelWhiteList}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDelWhiteList}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDelWhiteList}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onConfirmDelWhitelistItem}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenAddBlackListWhiteList}
        leastDestructiveRef={cancelRefAdd}
        onClose={onOpenAddBlackListWhiteList}
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {isAddBlacklist ? "Add blacklist" : "Add whitelist"}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex bgColor="bg.layout" w="100%" borderRadius="full">
                <InputGroup
                  color="white"
                  cursor="pointer"
                  px={2}
                  py={1}
                  alignItems="center"
                  display="flex"
                  borderWidth="1px"
                  borderColor="bg.borderSr"
                  borderRadius="md"
                >
                  <Input
                    placeholder="Search pair"
                    _focus={{ outline: "none" }}
                    type="text"
                    border="none"
                    h="auto"
                    value={search}
                    onInput={(event: ChangeEvent<HTMLInputElement>) => {
                      setSearch(event.target.value);
                    }}
                  />
                  {search && (
                    <Text
                      as="span"
                      color="gray.600"
                      fontSize="xs"
                      ml="auto"
                      bgColor="gray.200"
                      py="2px"
                      px="8px"
                      borderRadius="full"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      onClick={() => {
                        setSearch("");
                      }}
                    >
                      x
                    </Text>
                  )}
                </InputGroup>
              </Flex>
              {search && !isFetching && (
                <Flex
                  direction="column"
                  bgColor="white"
                  p={3}
                  pos="absolute"
                  zIndex={"dropdown"}
                  top="100px"
                  left="0"
                  w="100%"
                >
                  {isEmpty(result) && search && (
                    <Text display="flex" flexDirection="column">
                      <Text as="span" mb="10px" fontSize="xs" color="white">
                        No result found
                      </Text>
                    </Text>
                  )}
                  {!isEmpty(result) && search && (
                    <>
                      <Flex
                        direction="column"
                        px="10px"
                        py="8px"
                        w="100%"
                        maxHeight="440px"
                        overflowY="scroll"
                        bgColor={bgSearch}
                      >
                        {result &&
                          !isEmpty(result) &&
                          result.pages.map((page: any, i: number) => (
                            <React.Fragment key={`paging-${i}`}>
                              {page.map((data: any, index: number) => (
                                <SearchItem
                                  active={!index}
                                  key={`result-${index}`}
                                  item={data}
                                  onClose={onCloseSearch}
                                />
                              ))}
                            </React.Fragment>
                          ))}
                      </Flex>
                    </>
                  )}
                </Flex>
              )}

              {isAddBlacklist && (
                <Box mt={"2rem"}>
                  {currentBlackWhitePair?.chainId && (
                    <>
                      <Text mb="8px">Chain Id: </Text>
                      <Text mb="8px">{currentBlackWhitePair?.chainId}</Text>
                      <Divider />
                      <Text mb="8px" mt={"10px"}>
                        Pair:{" "}
                      </Text>
                      <Text mb="8px">{currentBlackWhitePair?.address}</Text>
                    </>
                  )}
                </Box>
              )}

              {!isAddBlacklist && (
                <Box mt={"2rem"}>
                  {currentBlackWhitePair?.chainId && (
                    <>
                      {" "}
                      <Text mb="8px">
                        Chain:{" "}
                        {
                          chains?.data?.find(
                            (item: any) =>
                              item.chainId === currentBlackWhitePair?.chainId
                          )?.name
                        }
                      </Text>
                      <Text mb="8px" mt={"10px"}>
                        Pair address: {currentBlackWhitePair?.address}
                      </Text>
                      <Text mb="8px" mt={"10px"}>
                        Live time from UTC (Default current:):
                      </Text>
                      <Input
                        placeholder="Live time from(milliseconds)"
                        size="md"
                        mb="8px"
                        type="datetime-local"
                        ref={liveTimeFromRef}
                        defaultValue={new Date(liveTimeData.liveTimeFrom)
                          .toISOString()
                          .replace("Z", "")}
                      />
                      Live time to UTC (Default current + 1h):
                      <Input
                        placeholder="Live time to(milliseconds)"
                        size="md"
                        mb="8px"
                        type="datetime-local"
                        ref={liveTimeToRef}
                        defaultValue={new Date(liveTimeData.liveTimeTo)
                          .toISOString()
                          .replace("Z", "")}
                      />
                      Position
                      <Input
                        placeholder="Position"
                        size="md"
                        mb="8px"
                        value={liveTimeData.position}
                        onChange={onChangePosition}
                      />
                    </>
                  )}
                  <Divider />
                </Box>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAddBlackListWhiteList}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={onConfirmAddBlackWhiteList}
                ml={3}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Tabs variant="enclosed" mt={"5rem"}>
        <TabList>
          <Tab>Trending</Tab>
          <Tab>Whitelist</Tab>
          <Tab>Blacklist</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box pt={{ base: "130px", md: "80px", xl: "20px" }}>
              <PairTrendingTable
                tableData={pairTrendings?.data || []}
                chains={chains?.data || []}
                onOpenEditRuleCheck={onClickEditRuleCheck}
              />
              <Paginate data={pairTrendings} callback={setPageIndex} />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box pt={{ base: "130px", md: "80px", xl: "20px" }}>
              <WhiteListPairTable
                tableData={pairWhiteList?.data || []}
                onClickDel={onClickDelWhiteListItem}
                onOpenAdd={onOpenAddWhiteList}
                onOpenEditRuleCheck={onClickEditRuleCheck}
              />
              <Paginate data={pairWhiteList} callback={setWhitelistPageIndex} />
            </Box>
          </TabPanel>
          <TabPanel>
            {isLoading ? (
              <Loading />
            ) : (
              <Box pt={{ base: "130px", md: "80px", xl: "20px" }}>
                <BlackListPairTable
                  tableData={pairBlackList?.data || []}
                  onClickDel={onClickDelBlackListItem}
                  onOpenAdd={onOpenAddBlackList}
                />
                <Paginate
                  data={pairBlackList}
                  callback={setBlacklistPageIndex}
                />
              </Box>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
