import { get, post, del, patch, getPublic } from "utils/BaseRequest";

export const getListPair = async (payload: {
  chain?: string;
  page?: number;
  limit?: number;
  keyword?: string;
}) => {
  const page = payload.page || 1;
  const limit = payload.limit || 10;
  const result = await get("/pair", {
    ...payload,
    page,
    limit,
  });
  return result;
};

export const addPair = async (data: any) => {
  const { data: result } = await post("/pair", data);
  return result;
};

export const updatePair = async (id: string, data: any) => {
  const { data: result } = await patch(`/pair/${id}`, data);
  return result;
};

export const delPair = async (id: string) => {
  const { data: result } = await del(`/pair/${id}`);
  return result;
};

export const searchPair = async (keyword: string, page: number) => {
  const searchObj: any = {
    keyword,
    page,
    limit: 30,
  };
  const { data: result } = await get("/pair-trending/search-pair", searchObj);
  return result;
};
