import {
  Box,
  Button,
  useDisclosure,
  Text,
  Flex,
  useColorModeValue,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Paginate from "components/Pagination";

import BlackListTable from "./components/DexviewscoreTable";
import useDebounce from "shared/hooks/useDebouce";
import useDexviewscore from "./hooks/useDexviewscore";
import Card from "components/card/Card";
import ModalAddDexviewscore from "./components/ModalAddDexviewscore";
import Loading from "components/loading";
import { MdArrowDropDown } from "react-icons/md";

export default function ManagePair() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const [pageIndex, setPageIndex] = React.useState(1);
  const [keyword, setKeyword] = React.useState<string>();
  const [address, setAddress] = React.useState<string>();
  const [status, setStatus] = useState<number>(1);
  const debounceValue = useDebounce(keyword, 1000);

  const { data, isLoading } = useDexviewscore({
    limit: 10,
    page: pageIndex,
    keyword: debounceValue,
  });

  const handleOpenAddModal = () => {
    onOpenAdd();
  };

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleChangeAddress = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleSelectStatus = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <ModalAddDexviewscore
        isOpen={isOpenAdd}
        currentId={undefined}
        onClose={onCloseAdd}
      />

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Dexview Score
          </Text>

          <Flex>
            <Input
              placeholder="Address"
              mr={"2rem"}
              backgroundColor={"white"}
              onChange={handleChangeAddress}
            />
            <Select
              icon={<MdArrowDropDown />}
              placeholder="All"
              bg={"white"}
              mr={"2rem"}
              maxW="150px"
              onChange={handleSelectStatus}
              value={status}
            >
              {[
                { name: "Active", value: 1 },
                { name: "Inactive", value: 0 },
              ].map((el) => {
                return (
                  <option key={el.name} value={el.value}>
                    {el.name}
                  </option>
                );
              })}
            </Select>
            <Button
              colorScheme="blue"
              minW={"120px"}
              onClick={handleOpenAddModal}
            >
              Add Score
            </Button>
          </Flex>
        </Flex>
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          maxW="98%"
          overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <BlackListTable tableData={data?.data || []} />
              <Paginate data={data} callback={setPageIndex} />
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
