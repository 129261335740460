import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { listChains } from "configs/chain";

import { listVerifyTokenInfo } from "configs/verifyTokenInfo";
import { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { FaTrashAlt } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import useAddOrUpdateTokenInfo from "../hooks/useAddOfUpdateTokenInfo";
import constants from "configs/constants";

interface IProps {
  isOpen: boolean;
  currentRow: any;
  onClose: () => void;
}
export default function ModalAddOrUpdate({
  isOpen,
  currentRow,
  onClose,
}: IProps) {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useAddOrUpdateTokenInfo({
    onClose,
    reset,
    id: currentRow?._id,
  });

  useEffect(() => {
    if (currentRow) {
      reset({ ...currentRow });
    }
  }, [currentRow, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "validators",
  });

  const onSubmit = (values: any) => {
    const validators = values?.validators?.length
      ? values?.validators?.map((el: any) => ({ type: el.type, url: el.url }))
      : [];
    const params = { ...values, validators };
    mutate(params);
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Token info</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ModalBody pb={6}>
            <SimpleGrid columns={2} spacing={10}>
              <Box>
                <Controller
                  name="chainId"
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FormControl isInvalid={Boolean(error?.message)} isRequired>
                      <FormLabel>Chain</FormLabel>
                      <Select placeholder="Select Chain" {...rest}>
                        {listChains.map((chain) => (
                          <option key={chain.value} value={chain.value}>
                            {chain.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        <>{error?.message}</>
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  rules={{ required: "This field is required" }}
                />
              </Box>
              <Box>
                <FormControl isInvalid={Boolean(errors.address)} isRequired>
                  <FormLabel>Address</FormLabel>
                  <Input
                    placeholder="Address"
                    {...register("address", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    <>{errors?.address?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.owner)}>
                  <FormLabel aria-required>Owner</FormLabel>
                  <Input placeholder="owner" {...register("owner")} />
                  <FormErrorMessage>
                    <>{errors?.owner?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.website)}>
                  <FormLabel>Website</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("website", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.website?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.telegram)}>
                  <FormLabel>Telegram</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("telegram", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.telegram?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.twitter)}>
                  <FormLabel>Twitter</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("twitter", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.twitter?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.discord)}>
                  <FormLabel>Discord</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("discord", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.discord?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.github)}>
                  <FormLabel>Github</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("github", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.github?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.logo)}>
                  <FormLabel>Logo</FormLabel>
                  <Input
                    placeholder="https://photos.pinksale.finance/file/pinksale-logo-upload/example.png"
                    {...register("logo", {
                      pattern: constants.REGEX_URL,
                    })}
                  />
                  <FormErrorMessage>
                    <>{errors?.logo?.message}</>
                  </FormErrorMessage>
                </FormControl>
                <Link
                  isExternal
                  _focus={{ boxShadow: "none" }}
                  color="blue.500"
                  href={"https://upload.pinksale.finance/"}
                >
                  Upload logo
                </Link>
              </Box>
            </SimpleGrid>

            <FormControl mt={4} isInvalid={Boolean(errors.description)}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="description"
                {...register("description")}
              />
              <FormErrorMessage>
                <>{errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>

            <Flex justify={"space-between"} mt={5}>
              <Text>Validators</Text>
              <IconButton
                colorScheme="blue"
                aria-label="Add"
                size="sm"
                fontSize={25}
                icon={<IoIosAddCircleOutline />}
                onClick={append}
              />
            </Flex>

            {fields.map((item, index) => (
              <Grid templateColumns={"25% 66% 5%"} gap={5} key={item.id} mt={4}>
                <Controller
                  name={`validators.${index}.type`}
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FormControl isInvalid={Boolean(error?.message)} isRequired>
                      <FormLabel>Type</FormLabel>
                      <Select placeholder="Select" {...rest}>
                        {listVerifyTokenInfo.map((chain) => (
                          <option key={chain.value} value={chain.value}>
                            {chain.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        <>{error?.message}</>
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  }}
                />
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <FormControl isInvalid={Boolean(error?.message)} isRequired>
                      <FormLabel>Url</FormLabel>
                      <Input {...field} placeholder="https://example.com" />
                      <FormErrorMessage>
                        <>{error?.message}</>
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  name={`validators.${index}.url`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This is required",
                    },
                  }}
                />

                <FormControl>
                  <FormLabel>&nbsp; </FormLabel>
                  <IconButton
                    colorScheme="red"
                    aria-label="remove"
                    size="sm"
                    fontSize={15}
                    icon={<FaTrashAlt />}
                    onClick={() => remove(index)}
                  />
                </FormControl>
              </Grid>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              colorScheme="blue"
              isLoading={isLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
