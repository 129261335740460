import {
  Box,
  Button,
  useDisclosure,
  Text,
  Flex,
  useColorModeValue,
  Input,
} from "@chakra-ui/react";
import React from "react";
import Paginate from "components/Pagination";

import BlackListTable from "./components/BlacklistTable";
import useDebounce from "shared/hooks/useDebouce";
import useBlacklist from "./hooks/useBlacklist";
import Card from "components/card/Card";
import ModalAddBlacklist from "./components/ModalAddBlacklist";
import Loading from "components/loading";

export default function ManagePair() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const [pageIndex, setPageIndex] = React.useState(1);
  const [keyword, setKeyword] = React.useState<string>();
  const debounceValue = useDebounce(keyword, 1000);

  const { data, isLoading } = useBlacklist({
    limit: 10,
    page: pageIndex,
    keyword: debounceValue,
  });

  const handleOpenAddModal = () => {
    onOpenAdd();
  };

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <ModalAddBlacklist
        isOpen={isOpenAdd}
        currentId={undefined}
        onClose={onCloseAdd}
      />

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Blacklist Token
          </Text>

          <Flex>
            <Input
              placeholder="Search"
              mr={"2rem"}
              backgroundColor={"white"}
              onChange={handleChangeKeyword}
            />
            <Button
              colorScheme="blue"
              minW={"120px"}
              onClick={handleOpenAddModal}
            >
              Block Token
            </Button>
          </Flex>
        </Flex>
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          maxW="98%"
          overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <BlackListTable tableData={data?.data || []} />
              <Paginate data={data} callback={setPageIndex} />
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
