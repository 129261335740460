import { useQuery } from "@tanstack/react-query";
import ApiResponse from "types/ApiResponse";
import { instance } from "utils/BaseRequest";
import { ITelegramGroup } from "../interface/telegramGroup";

const useSupportGroups = (keyword?: string) => {
  return useQuery(
    ["support-bot", keyword],
    async () =>
      (
        await instance.get<ApiResponse<ITelegramGroup[]>>(
          "/manager/support-bot",
          {
            params: { keyword: keyword || undefined },
          }
        )
      )?.data,
    {
      cacheTime: 10 * 60,
    }
  );
};

export default useSupportGroups;
