import { Flex, Text } from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Text fontSize="2xl" fontWeight="bold" m='2rem'>
        DexView Admin
      </Text>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
