import { getBlacklist, GetBlacklistParams } from 'utils/apiService/Blacklist';
import queryKey from 'configs/queryKey';
import { useQuery } from '@tanstack/react-query';

function useBlacklist({ page, limit, keyword }: GetBlacklistParams) {
  return useQuery(
    [queryKey.BLACK_LIST, page, limit, keyword],
    () => getBlacklist({ page, limit, keyword }),
    {
      enabled: Boolean(page) && Boolean(limit),
      staleTime: 60 * 1000,
    }
  );
}

export default useBlacklist;
