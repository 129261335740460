import { get, post, patch, del } from "utils/BaseRequest";

export const getListAccountAdmin = async (page = 1, limit = 10) => {
  const result = await get("/account", {
    page,
    limit,
  });
  return result;
};

export const addAccountAdmin = async (data: any) => {
  const { data: result } = await post("/account", data);
  return result;
};

export const updateAccountAdmin = async (id: string, data: any) => {
  const { data: result } = await patch(`/account/${id}`, data);
  return result;
};

export const deleteAccountAdmin = async (id: string) => {
  const { data: result } = await del(`/account/${id}`);
  return result;
};

export const getListRoleAdmin = async (page = 1, limit = 10) => {
  const result = await get("/role", {
    page,
    limit,
  });
  return result;
};

export const updateRoleAdmin = async (id: string, data: any) => {
  const { data: result } = await patch(`/role/${id}`, data);
  return result;
};
