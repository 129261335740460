import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { instance } from "utils/BaseRequest";

interface Props {
  groupIds: number[];
  onClose(): void;
  message: string;
}

const useSendMessage = ({ onClose, groupIds, message }: Props) => {
  const toast = useToast();
  return useMutation(
    async () =>
      await instance.post("/manager/support-bot/send-message", {
        groupIds,
        message,
      }),
    {
      onSuccess: () => {
        onClose();
        toast({
          title: "Send message",
          description: "Successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: async (errors: any) => {
        const devMessage = errors?.response?.data?.devMessage || [];
        const errorMessage = errors?.response?.data?.errorMessage || "";
        const description = errorMessage + ":" + devMessage?.join(", ") || "";

        toast({
          title: "Send message",
          description,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};

export default useSendMessage;
