import {
  Button,
  Textarea,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";
import useSendMessage from "../hooks/useSendMessage";

interface Props {
  isOpen: boolean;
  groupIds: number[];
  onClose(): void;
}

const ModalMessage = ({ isOpen, groupIds, onClose }: Props) => {
  const [message, setMessage] = useState("");
  const { mutate, isLoading } = useSendMessage({
    onClose: () => onCloseMessage(),
    groupIds,
    message,
  });
  const onCloseMessage = () => {
    onClose();
    setMessage("");
  };

  const onSendMessage = () => {
    mutate();
  };
  return (
    <Modal isOpen={isOpen} onClose={onCloseMessage}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            value={message}
            onChange={({ nativeEvent }) => {
              setMessage((nativeEvent.target as any)?.value);
            }}
            placeholder="Fill a message"
            _placeholder={{ color: "gray" }}
            bgColor={"white"}
            color="black"
            size="lg"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            colorScheme="blue"
            disabled={isLoading || !message}
            onClick={onSendMessage}
          >
            Send
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalMessage;
