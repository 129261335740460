import queryKey from "configs/queryKey";
import { useQuery } from "@tanstack/react-query";
import { getTotalTokenInfoRequest } from "utils/apiService/tokenInfoRequest";

function useTotalTokenInfoRequest() {
  return useQuery(
    [queryKey.TOTAL_TOKEN_INFO_REQUEST],
    () => getTotalTokenInfoRequest(),
    {
      enabled: true,
      staleTime: 60 * 1000,
      refetchInterval: 5000,
    }
  );
}

export default useTotalTokenInfoRequest;
