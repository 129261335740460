import { post, get, del, patch } from "utils/BaseRequest";

export const getListToken = async (payload: {
  chain?: string;
  page?: number;
  limit?: number;
  keyword?: string;
}) => {
  const page = payload.page || 1;
  const limit = payload.limit || 10;

  const result = await get("/token", {
    ...payload,
    page,
    limit,
  });
  return result;
};

export const delToken = async (id: string) => {
  const { data: result } = await del(`/token/${id}`);
  return result;
};

export const addToken = async (data: any) => {
  const { data: result } = await post("/token", data);
  return result;
};

export const updateToken = async (id: string, data: any) => {
  const { data: result } = await patch(`/token/${id}`, data);
  return result;
};

export const updateIsHoneypotToken = async (
  id: string,
  data: {
    [key: string]: any;
    isHoneypotSetByAdmin?: "0" | "1";
  }
) => {
  const mapping = {
    ["0"]: false,
    ["1"]: true,
  };

  const isHoneypotSetByAdmin = mapping[data?.isHoneypotSetByAdmin] ?? null;

  const { data: result } = await patch(`/token/is-honeypot/${id}`, {
    isHoneypotSetByAdmin,
  });
  return result;
};
