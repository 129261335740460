import { get, del, post, instance, put } from "utils/BaseRequest";

export interface GetCommunityWalletParams {
  pageIndex: number;
  pageSize: number;
  address?: string;
  keyword?: string;
  status?: number;
}

export interface CreateCommunityWalletParams {
  addresses: string[];
  description: string;
}

export interface UpdateCommunityWalletParams {
  address: string;
  description: string;
}

export const getCommunityWallet = async (params: GetCommunityWalletParams) => {
  const result = await get("/community-wallet", params);
  return result;
};

export const createCommunityWallet = async (
  params: CreateCommunityWalletParams
) => {
  await instance.post("manager/community-wallet", params);
};

export const removeCommunityWallet = async (_id: string) => {
  const result = await del(`/community-wallet/${_id}`);
  return result;
};

export const updateCommunityWallet = async (
  _id: string,
  params: UpdateCommunityWalletParams
) => {
  await instance.put(`manager/community-wallet/${_id}`, params);
};
