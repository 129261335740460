/* eslint-disable */
import {
  Box,
  Button,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  toast,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { RiPencilFill } from "react-icons/ri";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { subAddress } from "utils/helpers";

const handleCopy = (address: string | undefined) => {
  if (!address) return;
  navigator.clipboard.writeText(address);
  toast.notify(() => <span>Copy success</span>, {
    status: "success",
    duration: 5000,
  });
};

const columns = [
  {
    Header: "Position",
    accessor: (d: any) => {
      return (
        <span style={{ fontSize: "32px", fontWeight: "bold" }}>
          {d.position}
        </span>
      );
    },
  },
  {
    Header: "Rules",
    id: "rules",
    accessor: (d: any) => {
      if (d.status === 2) {
        return <Text color="red.400">Suspend</Text>;
      } else {
        return <Text color="green.400">OK</Text>;
      }
    },
  },
  {
    Header: "Pair",
    accessor: (d: any) => {
      return (
        <Text as="span" onClick={() => handleCopy(d?.address)} cursor="copy">
          {subAddress(d?.address)}
        </Text>
      );
    },
  },
  {
    Header: "Symbol",
    id: "baseTokenSymbol",
    accessor: (d: any) => {
      return (
        <div>
          {d.baseTokenSymbol} / {d.quoteTokenSymbol}
        </div>
      );
    },
  },

  {
    Header: "Live time from",
    id: "liveTimeFrom",
    accessor: (d: any) => {
      return <Text>{new Date(d.liveTimeFrom).toISOString()}</Text>;
    },
  },
  {
    Header: "Live time to",
    id: "liveTimeTo",
    accessor: (d: any) => {
      return <Text>{new Date(d.liveTimeTo).toISOString()}</Text>;
    },
  },
  {
    Header: "Status",
    id: "status",
    accessor: (d: any) => {
      const current = Date.now();
      const isActive = d.liveTimeFrom < current && d.liveTimeTo > current;
      const isUpcoming = d.liveTimeFrom > current;
      const hourLeft = (d?.liveTimeTo - current) / (1000 * 60 * 60);

      return (
        <Text>
          {isActive ? (
            <Text color="green.400">
              {hourLeft ? `${hourLeft.toFixed(1)}h left` : ""}
            </Text>
          ) : isUpcoming ? (
            <Text color="blue.400">Upcoming</Text>
          ) : (
            <Text color="red.400">Ended</Text>
          )}
        </Text>
      );
    },
  },
  {
    Header: "Action",
    accessor: "_id",
  },
];

export default function WhitelistPairTable(props: {
  tableData: any;
  onClickDel: any;
  onOpenAdd: any;
  onOpenEditRuleCheck: any;
}) {
  const { tableData, onClickDel, onOpenAdd, onOpenEditRuleCheck } = props;

  const tableInstance = useTable<{
    isIgnoreRuleChecker: boolean;
    [key: string]: any;
  }>(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("white", "navy.800");

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      maxW="98%"
      overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Whitelist Pair Trending
        </Text>
        <Button
          colorScheme="blue"
          onClick={() => {
            onOpenAdd();
          }}
        >
          Add Pair
        </Button>
      </Flex>
      <Box mx={10}>
        <Text as="p">
          Pair will be hidden on trending if price not match with our rules.
        </Text>
        <Text as="p">1. Price must be greater than listingPrice * 50%.</Text>
        <Text as="p">2. Price must be greater than trendingPrice * 80%.</Text>
      </Box>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  pos={{
                    base: column?.Header === "Action" ? "sticky" : "unset",
                  }}
                  zIndex={{
                    base: column?.Header === "Action" ? "banner" : "unset",
                  }}
                  right={{
                    base: column?.Header === "Action" ? "0px" : "unset",
                  }}
                  bgColor={bgColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data;

                  if (cell.column.Header === "Action") {
                    data = (
                      <>
                        <Button
                          colorScheme="red"
                          mr="5px"
                          mt="5px"
                          size="sm"
                          onClick={() => onClickDel(cell.row.values._id)}
                        >
                          Delete
                        </Button>
                      </>
                    );
                  } else if (cell.column.Header === "Rules") {
                    data = (
                      <Flex
                        onClick={() => onOpenEditRuleCheck(cell.row.original)}
                      >
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                        <Box px={1} cursor="pointer">
                          <Icon
                            as={RiPencilFill}
                            color={
                              cell.row.original["isIgnoreRuleChecker"] === true
                                ? "red"
                                : "green"
                            }
                          />
                        </Box>
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "12px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      pos={{
                        base:
                          cell.column?.Header === "Action" ? "sticky" : "unset",
                      }}
                      zIndex={{
                        base:
                          cell.column?.Header === "Action" ? "base" : "unset",
                      }}
                      right={{
                        base:
                          cell.column?.Header === "Action" ? "0px" : "unset",
                      }}
                      bgColor={bgColor}
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
