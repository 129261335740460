import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  useDisclosure,
  Text,
  Select,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import Paginate from "components/Pagination";

import {
  getListPair,
  delPair,
  updatePair,
  addPair,
  getListChain,
} from "utils/apiService";
import PairTable from "./components/PairTable";
import useDebounce from "shared/hooks/useDebouce";

const columns = [
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "ChainId",
    accessor: "chainId",
  },

  {
    Header: "Base Symbol",
    accessor: "token0Symbol",
  },
  {
    Header: "Quote Symbol",
    accessor: "token1Symbol",
  },
  {
    Header: "Base Name",
    accessor: "token0Name",
  },
  {
    Header: "Quote Name",
    accessor: "token1Name",
  },
  {
    Header: "Factory",
    accessor: "factory",
  },
  {
    Header: "token0",
    accessor: "token0",
  },
  {
    Header: "token1",
    accessor: "token1",
  },

  {
    Header: "decimal0",
    accessor: "decimal0",
  },
  {
    Header: "decimal1",
    accessor: "decimal1",
  },
  {
    Header: "blockNumber",
    accessor: "blockNumber",
  },
  {
    Header: "Action",
    accessor: "_id",
  },
];

export default function ManagePair() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const cancelRefAdd = React.useRef();

  const [pageIndex, setPageIndex] = React.useState(1);
  const [currentId, setCurrentId] = React.useState(null);
  const [currentPair, setCurrentPair] = React.useState({
    address: "",
    chainId: "",
    router: "",
    token0: "",
    token1: "",
    token0Name: "",
    token1Name: "",
    token0Symbol: "",
    token1Symbol: "",
    decimal0: 0,
    decimal1: 0,
    blockNumber: 0,
  });
  const [chain, setChain] = React.useState<string>();
  const [keyword, setKeyword] = React.useState<string>();

  const debounceValue = useDebounce(keyword, 1000);

  const { data: pairs } = useQuery(
    ["PAIR_LIST", chain, debounceValue, pageIndex],
    () =>
      getListPair({
        page: pageIndex,
        chain,
        keyword: debounceValue,
      })
  );

  const { data: chains } = useQuery(["CHAIN_LIST"], () =>
    getListChain(1, 1000)
  );

  const onClickDel = (id: string) => {
    onOpen();
    setCurrentId(id);
  };

  const onConfirmDel = async () => {
    try {
      if (currentId) {
        await delPair(currentId);
        toast({
          title: "Pair.",
          description: "Pair deleted.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries(["PAIR_LIST"]);
      }
    } catch {
      toast({
        title: "Pair.",
        description: "Pair can't deleted.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentId(null);
    onClose();
  };

  const onClickUpdate = (pair: any) => {
    onOpenAdd();
    setCurrentPair(pair);
    setCurrentId(pair._id);
  };

  const onClickOpenAdd = () => {
    onOpenAdd();
    setCurrentPair({
      address: "",
      chainId: "",
      router: "",
      token0: "",
      token1: "",
      token0Name: "",
      token1Name: "",
      token0Symbol: "",
      token1Symbol: "",
      decimal0: 0,
      decimal1: 0,
      blockNumber: 0,
    });
    setCurrentId(null);
  };

  const onConfirmAddOrUpdate = async () => {
    try {
      if (currentId) {
        await updatePair(currentId, currentPair);
        toast({
          title: "Pair.",
          description: "Pair updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        await addPair(currentPair);
        toast({
          title: "Pair.",
          description: "Pair added.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      await queryClient.invalidateQueries(["PAIR_LIST"]);
      setCurrentId(null);
      setCurrentPair({
        address: "",
        chainId: "",
        router: "",
        token0: "",
        token1: "",
        token0Name: "",
        token1Name: "",
        token0Symbol: "",
        token1Symbol: "",
        decimal0: 0,
        decimal1: 0,
        blockNumber: 0,
      });
    } catch {
      toast({
        title: "Pair.",
        description: "Pair can't updated.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    onCloseAdd();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onConfirmDel} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenAdd}
        leastDestructiveRef={cancelRefAdd}
        onClose={onCloseAdd}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {currentId ? "Update" : "Add"} Trending
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb="8px">Address</Text>
              <Input
                placeholder="Address"
                size="md"
                mb="8px"
                value={currentPair?.address || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, address: e.target.value })
                }
              />

              <Text mb="8px">ChainId</Text>
              <Input
                placeholder="ChainId"
                size="md"
                mb="8px"
                value={currentPair?.chainId || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, chainId: e.target.value })
                }
              />

              <Text mb="8px">Router</Text>
              <Input
                placeholder="Router"
                size="md"
                mb="8px"
                value={currentPair?.router || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, router: e.target.value })
                }
              />

              <Text mb="8px">Token 1</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.token1 || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, token1: e.target.value })
                }
              />

              <Text mb="8px">Token 1 Name</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.token1Name || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, token1Name: e.target.value })
                }
              />

              <Text mb="8px">Token 1 Symbol</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.token1Symbol || ""}
                onChange={(e) =>
                  setCurrentPair({
                    ...currentPair,
                    token1Symbol: e.target.value,
                  })
                }
              />

              <Text mb="8px">Token 1 Decimal</Text>
              <Input
                type={"number"}
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.decimal1 || 0}
                onChange={(e) =>
                  setCurrentPair({
                    ...currentPair,
                    decimal1: Number(e.target.value),
                  })
                }
              />

              <Text mb="8px">Token 0</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.token0 || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, token0: e.target.value })
                }
              />

              <Text mb="8px">Token 0 Name</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.token0Name || ""}
                onChange={(e) =>
                  setCurrentPair({ ...currentPair, token0Name: e.target.value })
                }
              />

              <Text mb="8px">Token 0 Symbol</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.token0Symbol || ""}
                onChange={(e) =>
                  setCurrentPair({
                    ...currentPair,
                    token0Symbol: e.target.value,
                  })
                }
              />

              <Text mb="8px">Token 0 Decimal</Text>
              <Input
                type={"number"}
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.decimal0 || 0}
                onChange={(e) =>
                  setCurrentPair({
                    ...currentPair,
                    decimal0: Number(e.target.value),
                  })
                }
              />

              <Text mb="8px">Block Number</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentPair?.blockNumber || 0}
                onChange={(e) =>
                  setCurrentPair({
                    ...currentPair,
                    blockNumber: Number(e.target.value),
                  })
                }
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAdd}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={onConfirmAddOrUpdate} ml={3}>
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <PairTable
          columnsData={columns}
          tableData={pairs?.data || []}
          onClickDel={onClickDel}
          onClickUpdate={onClickUpdate}
          onOpenAdd={onClickOpenAdd}
          onChangeChain={(chainId: string) => {
            setChain(chainId);
          }}
          onChangeKeyword={(keyword: string) => {
            setKeyword(keyword);
          }}
          chains={chains}
        />
        <Paginate data={pairs} callback={setPageIndex} />
      </Box>
    </>
  );
}
