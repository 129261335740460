export enum ChainIds {
  Ethereum = 1,
  Bsc = 56,
  Arb = 42161,
  Pol = 137,
  Pul = 369,
  Bit = 7171,
  Shi = 109,
  Cyb = 6661,
  Sol = 501424,
  Base = 8453,
  // Avalanche = 43114,
  // FantomOpera = 250,
}

export enum ChainNames {
  Bsc = "bsc",
  Ethereum = "eth",
  Arbitrum = "arbitrum",
  Polygon = "polygon",
  Pulse = "pulse",
  Bitrock = "bitrock",
  Shibariym = "shibarium",
  Cybria = "cybria",
  Solana = "solana",
  Base = "base",
  // Avalanche = 'avalanche',
  // FantomOpera = 'fantom',
}

export const listChains = [
  { name: ChainNames.Bsc, value: ChainIds.Bsc },
  { name: ChainNames.Ethereum, value: ChainIds.Ethereum },
  { name: ChainNames.Arbitrum, value: ChainIds.Arb },
  { name: ChainNames.Polygon, value: ChainIds.Pol },
  { name: ChainNames.Pulse, value: ChainIds.Pul },
  { name: ChainNames.Bitrock, value: ChainIds.Bit },
  { name: ChainNames.Shibariym, value: ChainIds.Shi },
  { name: ChainNames.Cybria, value: ChainIds.Cyb },
  { name: ChainNames.Solana, value: ChainIds.Sol },
  { name: ChainNames.Base, value: ChainIds.Base },
  // { name: ChainNames.Avalanche, value: ChainIds.Avalanche },
  // { name: ChainNames.BscTest, value: ChainIds.BscTest },
  // { name: ChainNames.FantomOpera, value: ChainIds.FantomOpera },
];

export const getChainNameFromChainId = (chainId: ChainIds) =>
  listChains.find((chain) => chain.value === chainId)?.name;
