import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Loading from "components/loading";
import Paginate from "components/Pagination";
import React, { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import useDebounce from "shared/hooks/useDebouce";
import ModalAddCommunityWallet from "./components/AddCommunityWallet";
import CommunityWalletTable from "./components/CommunityWalletTable";
import useCommunityWallet from "./hooks/useCommunityWallet";

export default function CommunityVote() {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [pageIndex, setPageIndex] = useState(1);
  const [keyword, setKeyword] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [status, setStatus] = useState<number>(1);

  const debounceValue = useDebounce(keyword, 1000);
  const debounceAddress = useDebounce(address, 1000);

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const { data, isLoading } = useCommunityWallet({
    pageIndex,
    pageSize: 10,
    keyword: debounceValue || undefined,
    address: debounceAddress || undefined,
    status,
  });

  const handleOpenAddModal = () => {
    onOpenAdd();
  };

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleChangeAddress = (e: any) => {
    setAddress(e.target.value);
  };

  const handleSelectStatus = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <ModalAddCommunityWallet isOpen={isOpenAdd} onClose={onCloseAdd} />

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex
          display={"flex"}
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        >
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Community wallets
          </Text>
          <Flex>
            <Input
              placeholder="Search"
              mr={"2rem"}
              backgroundColor={"white"}
              onChange={handleChangeKeyword}
            />
            <Input
              placeholder="Address"
              mr={"2rem"}
              backgroundColor={"white"}
              onChange={handleChangeAddress}
            />
            <Select
              icon={<MdArrowDropDown />}
              placeholder="All"
              bg={"white"}
              mr={"2rem"}
              maxW="150px"
              onChange={handleSelectStatus}
              value={status}
            >
              {[
                { name: "Active", value: 1 },
                { name: "Inactive", value: 0 },
              ].map((el) => {
                return (
                  <option key={el.name} value={el.value}>
                    {el.name}
                  </option>
                );
              })}
            </Select>
            <Button colorScheme="blue" onClick={handleOpenAddModal} minW={150}>
              Import Wallet
            </Button>
          </Flex>
        </Flex>

        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          maxW="98%"
          overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <CommunityWalletTable tableData={data?.data || []} />
              <Paginate data={data} callback={setPageIndex} />
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
