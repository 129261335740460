import queryKey from "configs/queryKey";
import { useQuery } from "@tanstack/react-query";
import { getRouterAnalysts } from "utils/apiService/Crawler";

function useRouterAnalyst() {
  return useQuery([queryKey.ROUTER_ANALYST], () => getRouterAnalysts(), {
    staleTime: Infinity,
    refetchInterval: 10000,
  });
}

export default useRouterAnalyst;
