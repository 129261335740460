import { ChainIds } from 'configs/chain';
import { get, instance } from 'utils/BaseRequest';

export interface GetDexviewscoreParams {
  page: number;
  limit: number;
  keyword?: string;
}

export interface AddDexviewscoreParams {
  chainId: ChainIds;
  address: string;
  information: number;
  transaction: number;
  holder: number;
  lpLock: number;
  kyc: number;
}

export const getDexviewscore = async ({
  page = 1,
  limit = 10,
  keyword,
}: GetDexviewscoreParams) => {
  const result = await get('/score', {
    page,
    limit,
    keyword,
  });
  return result;
};

export const addDexviewscore = async (params: AddDexviewscoreParams) => {
  return await instance.post('/manager/score', params);
};

export const updateDexviewscore = async (id: string, params: AddDexviewscoreParams) =>
  await instance.patch(`/manager/score/${id}`, params);

export const removeDexviewscore = async (id: string) =>
  await instance.delete(`/manager/score/${id}`);
