import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import useSupportGroups from "./hooks/useSupportGroups";
import debounce from "lodash/debounce";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import GroupItem from "./components/GroupItem";
import ModalMessage from "./components/ModalMessage";

const SupportBot = () => {
  const [keyword, setKeyword] = useState("");
  const { data: groups } = useSupportGroups(keyword);
  const inputRef = useRef<HTMLInputElement>(null);
  const setSearchValue = useRef(debounce(setKeyword, 600));
  const [selectTextGroups, setSelectedGroups] = useState<number[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onChangeText = useCallback(
    ({ target }: { target: { value: string } }) => {
      setSearchValue.current(target.value);
    },
    []
  );
  const onClear = useCallback(() => {
    setKeyword("");
    inputRef.current.value = "";
  }, []);
  const onClickClearInput = useCallback(() => {
    onClear();
    inputRef.current.focus();
  }, [onClear]);
  const onClickCheck = (id: number) => {
    if (selectTextGroups.includes(id)) {
      setSelectedGroups(selectTextGroups.filter((groupId) => groupId !== id));
    } else {
      setSelectedGroups([...selectTextGroups, id]);
    }
  };
  const onSelectAll = () => {
    if (selectTextGroups?.length === groups?.data?.length) {
      setSelectedGroups([]);
    } else {
      setSelectedGroups(groups?.data?.map((group) => group?.chatId));
    }
  };
  const onCloseModal = () => {
    onClose();
    setSelectedGroups([]);
  };

  return (
    <Box pt="20">
      <Flex>
        <Spacer />
        <Flex>
          <InputGroup boxShadow="sm" borderRadius="md" w="300px">
            <Input
              ref={inputRef}
              placeholder={"Search by group name"}
              _placeholder={{
                color: "gray",
              }}
              onChange={onChangeText}
              paddingInlineStart={4}
              paddingInlineEnd={10}
              borderTopRadius="md"
              borderBottomRadius={"md"}
              bgColor="white"
            />
            <InputRightElement
              onClick={onClickClearInput}
              pointerEvents={!!keyword?.length ? "auto" : "none"}
              color="black"
            >
              {keyword ? <IoMdCloseCircleOutline /> : <AiOutlineSearch />}
            </InputRightElement>
          </InputGroup>
          <Button
            variant="outline"
            borderRadius={10}
            w="150px"
            mx={4}
            onClick={onSelectAll}
          >
            {selectTextGroups?.length !== groups?.data?.length
              ? "Select all"
              : "Deselect all"}
          </Button>
          <Button
            variant="outline"
            borderRadius={10}
            disabled={!selectTextGroups?.length}
            onClick={onOpen}
          >
            {"Send message"}
          </Button>
        </Flex>
      </Flex>
      <TableContainer flex="1">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th w={"70px"} isNumeric>
                {!!selectTextGroups?.length ? "" : "STT"}
              </Th>
              <Th>Avatar</Th>
              <Th>Name</Th>
              <Th>Id</Th>
              <Th>Remind status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {groups?.data?.map((group, idx: number) => (
              <GroupItem
                key={String(idx)}
                isChecked={selectTextGroups.includes(group.chatId)}
                isShowCheck={!!selectTextGroups?.length}
                position={idx + 1}
                onClickCheck={() => onClickCheck(group?.chatId)}
                {...group}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <ModalMessage
        isOpen={isOpen}
        onClose={onCloseModal}
        groupIds={selectTextGroups}
      />
    </Box>
  );
};

export default SupportBot;
