import { Icon, Image, Td, Tr, useColorModeValue } from "@chakra-ui/react";
import { RemindStatus } from "configs/bot";
import {
  RiCheckboxCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";
import { ITelegramGroup } from "../interface/telegramGroup";

interface Props extends ITelegramGroup {
  position: number;
  isChecked: boolean;
  isShowCheck?: boolean;
  onClickCheck(): void;
}

const GroupItem = ({
  chatId,
  name,
  avatar,
  remindStatus,
  position,
  isChecked,
  isShowCheck,
  onClickCheck,
}: Props) => {
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );

  const remindStatusName: Record<RemindStatus, string> = {
    [RemindStatus.INACTIVE]: "OFF",
    [RemindStatus.ACTIVATE]: "ON",
  };

  return (
    <Tr
      _hover={{ bg: bgHover }}
      onClick={onClickCheck}
      cursor="pointer"
      h="100%"
      fontWeight="600"
    >
      <Td w={"70px"}>
        {isShowCheck ? (
          <Icon
            as={isChecked ? RiCheckboxCircleFill : RiCheckboxBlankCircleLine}
            w={6}
            h={6}
          />
        ) : (
          <b>{position}</b>
        )}
      </Td>
      <Td>
        <Image src={avatar} w="12" h="12" />
      </Td>
      <Td>{name}</Td>
      <Td>{chatId}</Td>
      <Td>{remindStatusName[remindStatus]}</Td>
    </Tr>
  );
};

export default GroupItem;
