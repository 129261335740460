import { del, get, instance, post, put } from "utils/BaseRequest";

export const getPairsTrending = async (page = 1, limit = 10) => {
  const result = await get("/pair-trending", {
    page,
    limit,
  });
  return result;
};

export const getBlacklistPair = async (page = 1, limit = 10) => {
  const result = await get("/pair-trending/blacklist", {
    page,
    limit,
  });
  return result;
};

export const getWhitelistPair = async (page = 1, limit = 10) => {
  const result = await get("/pair-trending/whitelist", {
    page,
    limit,
  });
  return result;
};

export const addBlacklistPair = async (param: {
  address: string;
  chainId: string;
}) => {
  const result = await post("/pair-trending/blacklist", param);

  return result;
};

export const addWhitelistPair = async (param: {
  address: string;
  chainId: string;
  liveTimeFrom: number;
  liveTimeTo: number;
}) => {
  const result = await post("/pair-trending/whitelist", param);

  return result;
};

export const removeBlacklistPair = async (param: {
  address: string;
  chainId: string;
}) => {
  const result = await del(
    `/pair-trending/blacklist/${param.chainId}/${param.address}`
  );

  return result;
};

export const removeWhitelistPair = async (param: {
  address: string;
  chainId: string;
  _id?: string;
}) => {
  const result = await del(`/pair-trending/whitelist/${param._id}`);

  return result;
};

export function switchIgnoreTrendingRuleCheck(
  _id: string,
  params: {
    isIgnoreRuleChecker: boolean;
  }
) {
  return put(`/pair-trending/whitelist/${_id}`, params);
}

export async function updatePairTrendingPaid(
  _id: string,
  params: { isIgnoreRuleChecker: boolean; status?: number }
) {
  return await instance.put(
    `/manager/pair-trending/whitelist-paid/${_id}`,
    params
  );
}
