import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import queryKey from 'configs/queryKey';
import {
  addBlacklist,
  AddBlacklistParams,
  updateBlacklist,
} from 'utils/apiService/Blacklist';

interface IProps {
  onClose: () => void;
  reset: () => void;
  id?: string;
}
const useAddBlacklist = ({ onClose, reset, id }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    id
      ? async (params: AddBlacklistParams) => await updateBlacklist(id, params)
      : async (params: AddBlacklistParams) => await addBlacklist(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.BLACK_LIST]);
        onClose();
        reset();
        toast({
          title: 'Blacklist',
          description: 'Block a token',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      },
      onError: async (errors: any) => {
        toast({
          title: 'Blacklist.',
          description: errors?.response?.data?.errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};

export default useAddBlacklist;
