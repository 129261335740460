import { post } from "utils/BaseRequest";

export const login = async (username: string, password: string) => {
  const { data } = await post("/auth/login", {
    username,
    password,
  });

  return data;
};
