import React from "react";
import { Flex, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { subAddress, formatNumber } from "utils/helpers";

const SearchItem = ({
  active,
  item,
  onClose,
}: {
  active: boolean;
  item: any;
  onClose?: any;
}) => {
  const bgItem = useColorModeValue("blue.500", "white");
  const color = useColorModeValue("white", "gray.800");

  const handleRedirect = () => {
    onClose && onClose({ chainId: item.chainId, address: item.address });
  };

  const tokenObj = {
    [item.token0]: {
      address: item.token0,
      name: item.token0Name,
      symbol: item.token0Symbol,
    },
    [item.token1]: {
      address: item.token1,
      name: item.token1Name,
      symbol: item.token1Symbol,
    },
  };

  return (
    <Link
      bgColor={bgItem}
      color={color}
      display="flex"
      w="100%"
      p={4}
      _hover={{
        textDecoration: "none",
        borderWidth: "1px",
      }}
      cursor="pointer"
      borderRadius="md"
      borderWidth={active ? "1px" : "0"}
      borderColor="blue.300"
      mb={4}
      zIndex={10000}
      onClick={handleRedirect}
    >
      <Flex direction="column" ml={5}>
        <Text fontSize="md">
          <Text as="span" fontWeight="bold" fontSize="md">
            {tokenObj[item.baseToken].symbol}
          </Text>
          <Text as="span" fontSize="md" mx={1}>
            /
          </Text>
          <Text as="span" fontSize="md">
            {tokenObj[item.quoteToken].symbol}
          </Text>
          <Text as="span" paddingLeft="1" fontSize="md">
            {tokenObj[item.baseToken].name}
          </Text>
        </Text>
        <Text
          display="flex"
          alignItems="flex-start"
          mt={2}
          flexDirection="column"
        >
          <Text as="span" fontSize="md" mr={3}>
            ${Number(item.priceUsd || 0).toFixed(7)}
          </Text>
          <Text as="span" fontSize="md" mr={3}>
            <Text as="span" fontSize="md" mr={1}>
              Liquidity:
            </Text>
            <Text as="span" fontSize="md">
              ${formatNumber(item.liquidity)}
            </Text>
          </Text>
        </Text>
        <Flex mt={2} fontSize="md">
          <Text as="span">Pair: {subAddress(item.address)}</Text>
          <Text as="span" ml={4}>
            {item.baseTokenSymbol}: {subAddress(item.baseToken)}
          </Text>
        </Flex>
      </Flex>
    </Link>
  );
};

export default React.memo(SearchItem);
