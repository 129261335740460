import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { uniq } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import useCreateCommunityWallet from "../hooks/useCreateCommunityWallet";
import useUpdateCommunityWallet from "../hooks/useUpdateCommunityWallet";

interface IProps {
  isOpen: boolean;
  currentRow: any;
  onClose: () => void;
}
export default function ModalUpdateCommunityWallet({
  isOpen,
  onClose,
  currentRow,
}: IProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useUpdateCommunityWallet({
    onClose,
    id: currentRow?._id,
  });

  const onSubmit = (values: any) => {
    const params = { ...values };
    mutate(params);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (currentRow) {
      reset({ ...currentRow });
    }
  }, [currentRow, reset]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update community wallet</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ModalBody>
            <FormControl isInvalid={Boolean(errors.description)}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                {...register("description")}
                defaultValue="DexView users"
              />
              <FormErrorMessage>
                <>{errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalBody pb={6}>
            <FormControl isInvalid={Boolean(errors.addresses)}>
              <FormLabel>Address</FormLabel>
              <Textarea {...register("address")} rows={10}></Textarea>
              <FormErrorMessage>
                <>{errors?.address?.message}</>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              colorScheme="blue"
              isLoading={isLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
