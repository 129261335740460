import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKey from "configs/queryKey";
import {
  addWhitelist,
  AddWhitelistParams,
  updateWhitelist,
} from "utils/apiService/Whitelist";

interface IProps {
  onClose: () => void;
  reset: () => void;
  id?: string;
}
const useAddWhitelist = ({ onClose, reset, id }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    id
      ? async (params: AddWhitelistParams) => await updateWhitelist(id, params)
      : async (params: AddWhitelistParams) => await addWhitelist(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.WHITE_LIST]);
        onClose();
        reset();
        toast({
          title: "Whitelist",
          description: "Block a token",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: async (errors: any) => {
        const devMessage = errors?.response?.data?.devMessage || [];
        const errorMessage = errors?.response?.data?.errorMessage || "";
        const description = errorMessage + ":" + devMessage?.join(", ") || "";

        toast({
          title: "Whitelist.",
          description,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};

export default useAddWhitelist;
