import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import useRemoveTokenInfo from '../hooks/useRemoveTokenInfo';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  currentId: string | undefined;
}
export default function ModalRemoveBlacklist({
  isOpen,
  onClose,
  currentId,
}: IProps) {
  const { mutate, isLoading } = useRemoveTokenInfo({ onClose, id: currentId });
  const handleRemove = () => {
    mutate();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure? You can't undo this action afterwards.
        </ModalBody>
        <ModalFooter>
          <Button
            variant='solid'
            colorScheme='red'
            onClick={handleRemove}
            isLoading={isLoading}
          >
            Delete
          </Button>
          <Button ml={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
