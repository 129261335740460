/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid, Spinner } from "@chakra-ui/react";
// import { columnsDataComplex } from "views/admin/dataTables/variables/columnsData";
// import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import React from "react";
import CrawlerTable from "./components/CrawlerTable";
import CrawlerTableSkeleton from "./components/CrawlerTableSkeleton";
import RouterAnalyst from "./components/RouterAnalyst";
import MonthlyUser from "./components/MonthlyUser";
import useCrawlers from "./hooks/useCrawlers";
import useRouterAnalyst from "./hooks/useRouterAnalyst";

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "LATEST CRAWLED BLOCK",
    accessor: "latestCrawledBlock",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export default function Crawler() {
  const { data: crawlers } = useCrawlers();
  const { data: analysts } = useRouterAnalyst();
  // console.log(JSON.stringify(analysts, null, 2));

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {Array.isArray(crawlers) ? (
          crawlers.map((item: any, index: any) => {
            item.tableData = item.tableData.map((el: any) => {
              const progress = Math.floor(
                (el.latestCrawledBlock / (item.latestBlock || 1)) * 100 || 0
              );
              return { ...el, progress };
            });

            return (
              <CrawlerTable
                key={index}
                title={item.title}
                latestBlock={item.latestBlock}
                columnsData={columnsDataComplex}
                tableData={item.tableData}
              />
            );
          })
        ) : (
          <>
            {/* <CrawlerTableSkeleton columnsData={columnsDataComplex} />
            <CrawlerTableSkeleton columnsData={columnsDataComplex} />
            <CrawlerTableSkeleton columnsData={columnsDataComplex} /> */}
            <Spinner />
          </>
        )}
      </SimpleGrid>
      <MonthlyUser data={analysts || []} />
      {/* <RouterAnalyst data={analysts} /> */}
    </Box>
  );
}
