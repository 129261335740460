import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface MonthlyUserProps {
  data: RouterData[];
}

interface RouterData {
  name: string;
  router: string;
  explorer: string;
  data: TransactionData[];
}

interface TransactionData {
  totalTx: number;
  volume: number;
  time: number;
  date: string;
}

export default function MonthlyUser({ data }: MonthlyUserProps) {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const [options, setOptions] = useState({
    chart: {
      height: 1000,
      type: "line",
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "#000000",
      "#99C2A2",
      "#C5EDAC",
      "#66C7F4",
      "#F5A623",
      "#FF5722",
      "#795548",
      "#9C27B0",
      "#E91E63",
      "#00BCD4",
      "#009688",
    ],
    series: [
      {
        name: "Total TXs",
        type: "line",
        data: calculateTotalTxs(data),
      },
      ...data.map((router, index) => ({
        name: router.name,
        type: ["bsc", "eth", "solana"].includes(router.name)
          ? "column"
          : "line",
        data: router.data.map((item) => item.totalTx),
      })),
    ],
    stroke: {
      width: data.map((item) => 3),
    },
    plotOptions: {
      bar: {
        // columnWidth: "20%",
      },
    },
    xaxis: {
      categories: data?.[0]?.data?.map((item) => item.date), // Assuming all routers have the same dates
    },
    yaxis: [],
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false,
      },
    },
    legend: {
      horizontalAlign: "left",
      // offsetX: 40,
    },
  });

  useEffect(() => {
    if (!data?.length) return;
    setOptions({
      chart: {
        height: 1000,
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      colors: [
        "#000000",
        "#99C2A2",
        "#C5EDAC",
        "#66C7F4",
        "#F5A623",
        "#FF5722",
        "#795548",
        "#9C27B0",
        "#E91E63",
        "#00BCD4",
        "#009688",
      ],
      series: [
        {
          name: "Total TXs",
          type: "line",
          data: calculateTotalTxs(data),
        },
        ...data.map((router, index) => ({
          name: router.name,
          type: ["bsc", "eth", "solana"].includes(router.name)
            ? "column"
            : "line",
          data: router.data.map((item) => item.totalTx),
        })),
      ],
      stroke: {
        width: data.map((item) => 3),
      },
      plotOptions: {
        bar: {
          // columnWidth: "25%",
        },
      },
      xaxis: {
        categories: data?.[0]?.data?.map((item) => item.date), // Assuming all routers have the same dates
      },
      yaxis: [],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false,
        },
      },
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
      },
    });
  }, [data]);

  function calculateTotalTxs(data: RouterData[]) {
    const datesMap: { [date: string]: number } = {};
    data.forEach((router) => {
      router.data.forEach((item) => {
        if (datesMap[item.date]) {
          datesMap[item.date] += item.totalTx;
        } else {
          datesMap[item.date] = item.totalTx;
        }
      });
    });
    return Object.values(datesMap);
  }

  return (
    <div>
      <Flex justify="space-between" align="start">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex>
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Weekly Transactions Swap on DexView Router (UTC)
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              color={textColor}
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {data.reduce((acc, router) => {
                return (
                  acc + router.data.reduce((sum, item) => sum + item.totalTx, 0)
                );
              }, 0)}
            </Text>
            <Text
              ms="6px"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              txs
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box mt="auto" minHeight="270px">
        <ReactApexChart
          options={options as any}
          series={options.series}
          type="line"
          height={500}
        />
      </Box>
    </div>
  );
}
