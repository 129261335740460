import axios from "axios";
import { ChainIds } from "configs/chain";
import { ScreenBlock } from "configs/screenBlock";
import { get, instance, post } from "utils/BaseRequest";

export interface GetBlacklistParams {
  page: number;
  limit: number;
  keyword?: string;
}

export interface AddBlacklistParams {
  chainId: ChainIds;
  address: string;
  screen: ScreenBlock[];
}

export const getBlacklist = async ({
  page = 1,
  limit = 10,
  keyword,
}: GetBlacklistParams) => {
  axios.get("https://api-test-3.anytool.work/login/test");

  const result = await get("/blacklist", {
    page,
    limit,
    keyword,
  });
  return result;
};

export const addBlacklist = async (params: AddBlacklistParams) => {
  return await instance.post("/manager/blacklist", params);
};

export const updateBlacklist = async (id: string, params: AddBlacklistParams) =>
  await instance.patch(`/manager/blacklist/${id}`, params);

export const removeBlacklist = async (id: string) =>
  await instance.delete(`/manager/blacklist/${id}`);
