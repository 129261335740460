import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { listChains } from "configs/chain";

import constants from "configs/constants";
import { TokenInfoRequest } from "configs/tokenInfoRequest";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useUpdateTokenInfo from "../hooks/useUpdateTokenInfo";

interface IProps {
  isOpen: boolean;
  currentRow: any;
  onClose: () => void;
}
export default function ModalUpdate({ isOpen, currentRow, onClose }: IProps) {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useUpdateTokenInfo({
    onClose,
    reset,
    id: currentRow?._id,
  });

  useEffect(() => {
    if (currentRow) {
      reset({ ...currentRow });
    }
  }, [currentRow, reset]);

  const onApprove = (values: any) => {
    const params = { ...values, status: TokenInfoRequest.ACCEPTED };

    mutate(params);
  };

  const onReject = (values: any) => {
    const params = { ...values, status: TokenInfoRequest.REJECTED };
    mutate(params);
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Token Request</ModalHeader>
        <ModalCloseButton />

        <form noValidate>
          <ModalBody pb={6}>
            <SimpleGrid columns={2} spacing={10}>
              <Box>
                <Controller
                  name="chainId"
                  control={control}
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FormControl isInvalid={Boolean(error?.message)} isRequired>
                      <FormLabel>Chain</FormLabel>
                      <Select placeholder="Select Chain" {...rest}>
                        {listChains.map((chain) => (
                          <option key={chain.value} value={chain.value}>
                            {chain.name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        <>{error?.message}</>
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  rules={{ required: "This field is required" }}
                />
              </Box>
              <Box>
                <FormControl isInvalid={Boolean(errors.address)} isRequired>
                  <FormLabel>Address</FormLabel>
                  <Input
                    placeholder="Address"
                    {...register("address", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    <>{errors?.address?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.owner)}>
                  <FormLabel aria-required>Owner</FormLabel>
                  <Input placeholder="owner" {...register("owner")} />
                  <FormErrorMessage>
                    <>{errors?.owner?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.website)}>
                  <FormLabel>Website</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("website", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.website?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.telegram)}>
                  <FormLabel>Telegram</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("telegram", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.telegram?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.twitter)}>
                  <FormLabel>Twitter</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("twitter", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.twitter?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.discord)}>
                  <FormLabel>Discord</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("discord", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.discord?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>

              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.github)}>
                  <FormLabel>Github</FormLabel>
                  <Input
                    placeholder="https://example.com"
                    {...register("github", { pattern: constants.REGEX_URL })}
                  />
                  <FormErrorMessage>
                    <>{errors?.github?.message}</>
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box>
                <FormControl mt={4} isInvalid={Boolean(errors.logo)}>
                  <FormLabel>Logo</FormLabel>
                  <Input
                    placeholder="https://photos.pinksale.finance/file/pinksale-logo-upload/example.png"
                    {...register("logo", {
                      pattern: constants.REGEX_URL,
                    })}
                  />
                  <FormErrorMessage>
                    <>{errors?.logo?.message}</>
                  </FormErrorMessage>
                </FormControl>
                <Link
                  isExternal
                  _focus={{ boxShadow: "none" }}
                  color="blue.500"
                  href={"https://upload.pinksale.finance/"}
                >
                  Upload logo
                </Link>
              </Box>
            </SimpleGrid>

            <FormControl mt={4} isInvalid={Boolean(errors.description)}>
              <FormLabel>Description</FormLabel>
              <Textarea
                placeholder="description"
                {...register("description")}
              />
              <FormErrorMessage>
                <>{errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              colorScheme="green"
              isLoading={isLoading}
              type="button"
              onClick={handleSubmit(onApprove)}
            >
              Approve
            </Button>
            <Button
              mr={4}
              colorScheme="red"
              isLoading={isLoading}
              type="button"
              onClick={handleSubmit(onReject)}
            >
              Reject
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
