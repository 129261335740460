import queryKey from "configs/queryKey";
import { useQuery } from "@tanstack/react-query";
import {
  getCommunityWallet,
  GetCommunityWalletParams,
} from "utils/apiService/CommunityWallet";

function useCommunityWallet(params: GetCommunityWalletParams) {
  return useQuery(
    [queryKey.COMMUNITY_WALLET, params],
    () => getCommunityWallet(params),
    {
      enabled: true,
      staleTime: 60 * 1000,
    }
  );
}

export default useCommunityWallet;
