import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKey from "configs/queryKey";
import { removeCommunityWallet } from "utils/apiService/CommunityWallet";

interface IProps {
  onClose: () => void;
  id: string;
}
const useRemoveCommunityWallet = ({ onClose, id }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(async () => await removeCommunityWallet(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.COMMUNITY_WALLET]);
      onClose();
      toast({
        title: "Community wallet",
        description: "Remove a community wallet successful",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: async (errors: any) => {
      const devMessage = errors?.response?.data?.devMessage || [];
      const errorMessage = errors?.response?.data?.errorMessage || "";
      const description = errorMessage + ":" + devMessage?.join(", ") || "";

      toast({
        title: "Community wallet",
        description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export default useRemoveCommunityWallet;
