import { post, get, del, patch } from "utils/BaseRequest";

export const getListChain = async (page = 1, limit = 10) => {
  const result = await get("/chain", {
    page,
    limit,
  });  
  return result;
};

export const delChain = async (id: string) => {
  const { data: result } = await del(`/chain/${id}`);
  return result;
};

export const addChain = async (data: any) => {
  const { data: result } = await post("/chain", data);
  return result;
};

export const updateChain = async (id: string, data: any) => {
  const { data: result } = await patch(`/chain/${id}`, data);
  return result;
};
