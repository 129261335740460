import { ChainIds } from 'configs/chain';
import { get, instance } from 'utils/BaseRequest';

export interface GetWhitelistParams {
  page: number;
  limit: number;
  keyword?: string;
}

export interface AddWhitelistParams {
  chainId: ChainIds;
  address: string;
}

export const getWhitelist = async ({
  page = 1,
  limit = 10,
  keyword,
}: GetWhitelistParams) => {
  const result = await get('/whitelist', {
    page,
    limit,
    keyword,
  });
  return result;
};

export const addWhitelist = async (params: AddWhitelistParams) => {
  return await instance.post('/manager/whitelist', params);
};

export const updateWhitelist = async (id: string, params: AddWhitelistParams) =>
  await instance.patch(`/manager/whitelist/${id}`, params);

export const removeWhitelist = async (id: string) =>
  await instance.delete(`/manager/whitelist/${id}`);
