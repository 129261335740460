import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKey from "configs/queryKey";
import { removeDexviewscore } from "utils/apiService/Score";

interface IProps {
  onClose: () => void;
  id: string;
}
const useRemoveDexviewscore = ({ onClose, id }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(async () => await removeDexviewscore(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.DEXVIEW_SCORE]);
      onClose();
      toast({
        title: "Dexviewscore",
        description: "Remove a token successful",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: async (errors: any) => {
      const devMessage = errors?.response?.data?.devMessage || [];
      const errorMessage = errors?.response?.data?.errorMessage || "";
      const description = errorMessage + ":" + devMessage?.join(", ") || "";

      toast({
        title: "Dexviewscore.",
        description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export default useRemoveDexviewscore;
