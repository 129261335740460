import {
  Box,
  Button,
  useDisclosure,
  Text,
  Flex,
  useColorModeValue,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Paginate from "components/Pagination";
import { MdArrowDropDown } from "react-icons/md";
import { TokenInfoRequest } from "configs/tokenInfoRequest";
import TokenInfoTable from "./components/TokenInfoTable";
import useDebounce from "shared/hooks/useDebouce";
import useTokenInfoRequest from "./hooks/useTokenInfoRequest";
import Card from "components/card/Card";
import ModalAddOrUpdate from "./components/ModalUpdate";
import Loading from "components/loading";
import { listChains } from "configs/chain";
import { listTokenInfoRequestStatus } from "configs/tokenInfoRequest";

export default function ManagePair() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const [pageIndex, setPageIndex] = useState(1);
  const [chainId, setChainId] = useState(undefined);
  const [status, setStatus] = useState([TokenInfoRequest.PENDING]);

  const [keyword, setKeyword] = useState<string>();
  const debounceValue = useDebounce(keyword, 1000);

  const { data, isLoading } = useTokenInfoRequest({
    pageIndex,
    pageSize: 10,
    keyword: debounceValue,
    chainId,
    status,
  });

  const handleSelectChain = (e: any) => {
    setChainId(e.target.value || undefined);
  };
  const handleSelectStatus = (e: any) => {
    setStatus(e.target.value ? [e.target.value] : []);
  };

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <ModalAddOrUpdate
        isOpen={isOpenAdd}
        currentRow={undefined}
        onClose={onCloseAdd}
      />

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex
          display={"flex"}
          px="25px"
          justify="space-between"
          mb="20px"
          align="center"
        >
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Token Info Request
          </Text>
          <Flex>
            <Select
              icon={<MdArrowDropDown />}
              placeholder="All status"
              bg={"white"}
              mr={"2rem"}
              maxW="150px"
              onChange={handleSelectStatus}
              defaultValue={TokenInfoRequest.PENDING}
            >
              {listTokenInfoRequestStatus.map((el) => {
                return (
                  <option key={el.name} value={el.value}>
                    {el.name}
                  </option>
                );
              })}
            </Select>
            <Select
              icon={<MdArrowDropDown />}
              placeholder="All chain"
              bg={"white"}
              mr={"2rem"}
              maxW="150px"
              onChange={handleSelectChain}
            >
              {listChains?.map((chain) => {
                return (
                  <option key={chain.name} value={chain.value}>
                    {chain.name}
                  </option>
                );
              })}
            </Select>
            <Input
              placeholder="Search"
              mr={"2rem"}
              backgroundColor={"white"}
              onChange={handleChangeKeyword}
            />
          </Flex>
        </Flex>
        <Card
          flexDirection="column"
          w="100%"
          px="0px"
          maxW="98%"
          overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TokenInfoTable tableData={data?.data || []} />
              <Paginate data={data} callback={setPageIndex} />
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
