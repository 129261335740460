import React, { useMemo, useState, useEffect } from "react";
import { Flex, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

const Paginate = ({ data, callback }: { data: any; callback: any }) => {
  const hiBg = useColorModeValue("gray", "white");

  const [pageIndex, setPageIndex] = useState<number>(data?.pageIndex || 1);
  const pageSize = data?.pageSize || 10;
  const totalPages = data?.totalPages || 0;
  const totalItems = data?.totalItems || 0;

  useEffect(() => {
    if (pageIndex) {
      callback(pageIndex);
    }
  }, [pageIndex]);

  const from = (pageIndex - 1) * pageSize;
  const to = Math.min(from + pageSize, totalItems);

  const hasNextPage = totalPages > pageIndex;
  const nextPageFrom = from + pageSize;
  const nextPageTo = Math.min(to + pageSize, totalItems);

  return (
    <Flex alignItems="center" justifyContent="center" mt="1rem" pb="50px">
      <Text as="span" fontSize="sm">
        Showing {from}-{to} of {data?.totalItems}
      </Text>
      {pageIndex > 1 && (
        <Flex
          fontSize="sm"
          borderWidth="1px"
          borderColor={hiBg}
          borderRadius="md"
          justifyContent="center"
          alignItems="center"
          ml={4}
          px={3}
          py={1}
          cursor="pointer"
          onClick={() => setPageIndex((i: number) => i - 1)}
        >
          <Icon as={ChevronLeftIcon} w="13px" h="13px" />
          {(pageIndex - 2) * 10 + 1}-{(pageIndex - 1) * 10}
        </Flex>
      )}

      {hasNextPage && (
        <Flex
          fontSize="sm"
          borderWidth="1px"
          borderColor={hiBg}
          borderRadius="md"
          justifyContent="center"
          alignItems="center"
          ml={4}
          px={3}
          py={1}
          cursor="pointer"
          onClick={() => setPageIndex((i: number) => i + 1)}
        >
          {`${nextPageFrom}-${nextPageTo}`}
          <Icon as={ChevronRightIcon} w="13px" h="13px" />
        </Flex>
      )}
    </Flex>
  );
};

export default Paginate;
