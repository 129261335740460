import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKey from "configs/queryKey";
import {
  addDexviewscore,
  AddDexviewscoreParams,
  updateDexviewscore,
} from "utils/apiService/Score";

interface IProps {
  onClose: () => void;
  reset: () => void;
  id?: string;
}
const useAddDexviewscore = ({ onClose, reset, id }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    id
      ? async (params: AddDexviewscoreParams) =>
          await updateDexviewscore(id, params)
      : async (params: AddDexviewscoreParams) => await addDexviewscore(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.DEXVIEW_SCORE]);
        onClose();
        reset();
        toast({
          title: "Dexviewscore",
          description: "Add a Dexview score",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: async (errors: any) => {
        const devMessage = errors?.response?.data?.devMessage || [];
        const errorMessage = errors?.response?.data?.errorMessage || "";
        const description = errorMessage + ":" + devMessage?.join(", ") || "";

        toast({
          title: "Dexviewscore.",
          description,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};

export default useAddDexviewscore;
