import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import { Route, Router, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const browserHistory = createBrowserHistory();
const queryCache = new QueryCache({
  onError: (error: any) => error,
});
const queryClient: QueryClient = new QueryClient({ queryCache });

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <Router history={browserHistory}>
          <Switch>
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/auth`} component={AuthLayout} />
            <Redirect from="/" to="/admin/default" />
          </Switch>
        </Router>
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
