import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { uniq } from "lodash";
import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import useCreateCommunityWallet from "../hooks/useCreateCommunityWallet";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function ModalAddCommunityWallet({ isOpen, onClose }: IProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const { isLoading, mutate } = useCreateCommunityWallet({
    onClose,
    reset,
  });

  const onSubmit = (values: any) => {
    const addresses = uniq(
      values.addresses
        .split("\t")
        .join(" ")
        .split(/[ ,\n]+/)
        .map((item: string) => item.trim())
        .filter((item: string) => item)
    );

    const params = { ...values, addresses };
    mutate(params);
    setTimeout(() => reset(), 1000);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const recipients = e.target?.result;

      if (typeof recipients === "string") {
        setValue("addresses", recipients);
      }
    };

    if (e.target.files) {
      reader.readAsText(e.target.files[0]);
      e.target.value = "";
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import community wallets</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ModalBody>
            <FormControl isInvalid={Boolean(errors.description)}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                {...register("description")}
                defaultValue="DexView users"
              />
              <FormErrorMessage>
                <>{errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalBody pb={6}>
            <FormControl isInvalid={Boolean(errors.addresses)}>
              <FormLabel>Addresses</FormLabel>
              <Textarea
                {...register("addresses")}
                placeholder={`Insert allocation: separate with breaks line, space or comma
Ex:
0x0000000000000000000000000000000000000000
0x0000000000000000000000000000000000000001
0x0000000000000000000000000000000000000002`}
                rows={10}
              ></Textarea>
              <FormErrorMessage>
                <>{errors?.addresses?.message}</>
              </FormErrorMessage>
            </FormControl>

            <FormLabel pt={5}>Import file .csv</FormLabel>
            <Input type="file" onChange={handleFileChange} />
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              colorScheme="blue"
              isLoading={isLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
