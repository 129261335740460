import {
  Box,
  Button,
  useDisclosure,
  Text,
  Flex,
  useColorModeValue,
  Input,
  Select,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Paginate from 'components/Pagination';
import { MdArrowDropDown } from 'react-icons/md';

import TokenInfoTable from './components/TokenInfoTable';
import useDebounce from 'shared/hooks/useDebouce';
import useTokenInfo from './hooks/useTokenInfo';
import Card from 'components/card/Card';
import ModalAddOrUpdate from './components/ModalAddOrUpdate';
import Loading from 'components/loading';
import { listChains } from 'configs/chain';

export default function ManagePair() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();

  const [pageIndex, setPageIndex] = useState(1);
  const [chainId, setChainId] = useState(undefined);

  const [keyword, setKeyword] = useState<string>();
  const debounceValue = useDebounce(keyword, 1000);

  const { data, isLoading } = useTokenInfo({
    pageIndex,
    pageSize: 10,
    keyword: debounceValue,
    chainId,
  });

  const handleSelectChain = (e: any) => {
    setChainId(e.target.value || undefined);
  };

  const handleOpenAddModal = () => {
    onOpenAdd();
  };

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <ModalAddOrUpdate
        isOpen={isOpenAdd}
        currentRow={undefined}
        onClose={onCloseAdd}
      />

      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex
          display={'flex'}
          px='25px'
          justify='space-between'
          mb='20px'
          align='center'
        >
          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'
          >
            Token Info
          </Text>
          <Flex>
            <Select
              icon={<MdArrowDropDown />}
              placeholder='All'
              bg={'white'}
              mr={'2rem'}
              maxW='150px'
              onChange={handleSelectChain}
            >
              {listChains?.map((chain) => {
                return (
                  <option key={chain.name} value={chain.value}>
                    {chain.name}
                  </option>
                );
              })}
            </Select>
            <Input
              placeholder='Search'
              mr={'2rem'}
              backgroundColor={'white'}
              onChange={handleChangeKeyword}
            />
            <Button colorScheme='blue' onClick={handleOpenAddModal} minW={150}>
              Add Token Info
            </Button>
          </Flex>
        </Flex>
        <Card
          flexDirection='column'
          w='100%'
          px='0px'
          maxW='98%'
          overflowX={{ base: 'auto', sm: 'scroll', lg: 'auto' }}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TokenInfoTable tableData={data?.data || []} />
              <Paginate data={data} callback={setPageIndex} />
            </>
          )}
        </Card>
      </Box>
    </>
  );
}
