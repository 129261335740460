import { getDexviewscore, GetDexviewscoreParams } from 'utils/apiService/Score';
import queryKey from 'configs/queryKey';
import { useQuery } from '@tanstack/react-query';

function useDexviewscore({ page, limit, keyword }: GetDexviewscoreParams) {
  return useQuery(
    [queryKey.DEXVIEW_SCORE, page, limit, keyword],
    () => getDexviewscore({ page, limit, keyword }),
    {
      enabled: Boolean(page) && Boolean(limit),
      staleTime: 60 * 1000,
    }
  );
}

export default useDexviewscore;
