import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { listChains } from "configs/chain";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useAddDexviewscore from "../hooks/useAddDexviewscore";

interface IProps {
  isOpen: boolean;
  currentId: any;
  onClose: () => void;
}
export default function ModalAddDexviewscore({
  isOpen,
  currentId,
  onClose,
}: IProps) {
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({});
  const { isLoading, mutate } = useAddDexviewscore({
    onClose,
    reset,
    id: currentId?._id,
  });

  useEffect(() => {
    reset({
      chainId: currentId?.chainId,
      address: currentId?.address,
      status: currentId?.status,
      information: currentId?.information,
      transaction: currentId?.transaction,
      holder: currentId?.holder,
      lpLock: currentId?.lpLock,
      kyc: currentId?.kyc,
    });
  }, [currentId, reset]);

  const onSubmit = (values: any) => {
    for (const key in values) {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        const value = values[key];
        if (key !== "address" && key !== "chainId") {
          values[key] = Number(value);
        }
      }
    }
    mutate(values);
  };
  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>DexView Score</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <ModalBody pb={6}>
            <Controller
              name="chainId"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FormControl isInvalid={Boolean(error?.message)} isRequired>
                  <FormLabel>Chain</FormLabel>
                  <Select placeholder="Select Chain" {...rest}>
                    {listChains.map((chain) => (
                      <option key={chain.value} value={chain.value}>
                        {chain.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    <>{error?.message}</>
                  </FormErrorMessage>
                </FormControl>
              )}
              rules={{
                required: {
                  value: true,
                  message: "This is required",
                },
              }}
            />

            <FormControl mt={4} isInvalid={Boolean(errors.address)} isRequired>
              <FormLabel>Address</FormLabel>
              <Input
                placeholder="Address"
                {...register("address", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.address?.message}</>
              </FormErrorMessage>
            </FormControl>

            <Controller
              name="status"
              control={control}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <FormControl isInvalid={Boolean(error?.message)} isRequired>
                  <FormLabel>Status</FormLabel>
                  <Select placeholder="Select Status" {...rest}>
                    <option key={"1"} value={1}>
                      Active
                    </option>
                    <option key={"0"} value={0}>
                      Inactive
                    </option>
                  </Select>
                  <FormErrorMessage>
                    <>{error?.message}</>
                  </FormErrorMessage>
                </FormControl>
              )}
              rules={{
                required: {
                  value: true,
                  message: "This is required",
                },
              }}
            />

            <FormControl
              mt={4}
              isInvalid={Boolean(errors.information)}
              isRequired
            >
              <FormLabel>Information</FormLabel>
              <Input
                type="number"
                min={0}
                max={99}
                placeholder="Information"
                {...register("information", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.information?.message}</>
              </FormErrorMessage>
            </FormControl>

            <FormControl
              mt={4}
              isInvalid={Boolean(errors.transaction)}
              isRequired
            >
              <FormLabel>Transaction</FormLabel>
              <Input
                type="number"
                min={0}
                max={99}
                placeholder="Transaction"
                {...register("transaction", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.transaction?.message}</>
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={Boolean(errors.holder)} isRequired>
              <FormLabel>Holder</FormLabel>
              <Input
                type="number"
                min={0}
                max={99}
                placeholder="Holder"
                {...register("holder", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.holder?.message}</>
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={Boolean(errors.lpLock)} isRequired>
              <FormLabel>lpLock</FormLabel>
              <Input
                type="number"
                min={0}
                max={99}
                placeholder="LpLock"
                {...register("lpLock", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.lpLock?.message}</>
              </FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={Boolean(errors.kyc)} isRequired>
              <FormLabel>Kyc</FormLabel>
              <Input
                type="number"
                min={0}
                max={99}
                placeholder="Kyc"
                {...register("kyc", {
                  required: "This is required",
                })}
              />
              <FormErrorMessage>
                <>{errors?.kyc?.message}</>
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={4}
              colorScheme="blue"
              isLoading={isLoading}
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={handleClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
