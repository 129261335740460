export enum ScreenBlock {
  TRENDING = 'TRENDING',
  PAIR_ACTIVITY = 'PAIR_ACTIVITY',
  BASE_TOKEN_PAIRS = 'BASE_TOKEN_PAIRS',
  WALLET = 'WALLET',
  SEARCH = 'SEARCH',
}

export const listScreenBlock = [
  { name: 'Trending', value: ScreenBlock.TRENDING },
  { name: 'Pair activity', value: ScreenBlock.PAIR_ACTIVITY },
  { name: 'Base token pairs', value: ScreenBlock.BASE_TOKEN_PAIRS },
  { name: 'Wallet', value: ScreenBlock.WALLET },
  { name: 'Search', value: ScreenBlock.SEARCH },
];

export const getScreenBlockFromKey = (key: string | undefined) =>
  listScreenBlock.find((el) => el.value === key)?.name;
