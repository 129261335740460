const queryKey = {
  BLACK_LIST: "BLACK_LIST",
  CRAWLERS: "CRAWLERS",
  ROUTER_ANALYST: "ROUTER_ANALYST",
  TOKEN_INFO: "TOKEN_INFO",
  TOTAL_TOKEN_INFO_REQUEST: "TOTAL_TOKEN_INFO_REQUEST",
  TOKEN_INFO_REQUEST: "TOKEN_INFO_REQUEST",
  COMMUNITY_WALLET: "COMMUNITY_WALLET",
  WHITE_LIST: "WHITE_LIST",
  DEXVIEW_SCORE: "DEXVIEW_SCORE",
};

export default queryKey;
