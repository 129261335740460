import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKey from "configs/queryKey";
import {
  createCommunityWallet,
  CreateCommunityWalletParams,
} from "utils/apiService/CommunityWallet";

interface IProps {
  onClose: () => void;
  reset: () => void;
}
const useCreateCommunityWallet = ({ onClose, reset }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(
    (params: CreateCommunityWalletParams) => createCommunityWallet(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.COMMUNITY_WALLET]);
        reset();
        onClose();
        toast({
          title: "Community wallet",
          description: "Create a community wallet successful",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      },
      onError: async (errors: any) => {
        const devMessage = errors?.response?.data?.devMessage || [];
        const errorMessage = errors?.response?.data?.errorMessage || "";
        const description = errorMessage + ":" + devMessage?.join(", ") || "";

        toast({
          title: "Community wallet",
          description,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );
};

export default useCreateCommunityWallet;
