import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Field, FieldProps, Form, Formik } from "formik";
import { updatePairTrendingPaid } from "utils/apiService";
import * as Yup from "yup";

enum RuleCheckerStatus {
  Enable = "enable",
  Disable = "disable",
}

const validationSchema = Yup.object().shape({
  isIgnoreRuleChecker: Yup.string(),
  // status: Yup.number(),
});

interface EditRuleChecker {
  isIgnoreRuleChecker?: string;
  status?: number;
}

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  currentEditRuleCheckPair: {
    _id: string;
    chainId: string;
    address: string;
    status: number;
    isIgnoreRuleChecker?: boolean;
  };
}

function ModalEditRuleCheck({
  isOpen,
  onClose,
  currentEditRuleCheckPair,
}: IProps) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const handleSubmit = async (
    values: EditRuleChecker,
    { setSubmitting }: any
  ) => {
    const mapping: any = {
      enable: true,
      disable: false,
    };
    const key: any = values?.isIgnoreRuleChecker ?? RuleCheckerStatus.Disable;

    const result: {
      isIgnoreRuleChecker: boolean;
      status?: number;
    } = {
      isIgnoreRuleChecker: mapping[key] ?? true,
      status: values?.status ? Number(values?.status) : undefined,
    };

    try {
      await updatePairTrendingPaid(currentEditRuleCheckPair._id, result);
      await queryClient.invalidateQueries(["WHITELIST_PAIR"]);
      await queryClient.invalidateQueries(["BLACKLIST_PAIR"]);
      await queryClient.invalidateQueries(["PAIR_TRENDING_LIST"]);
      toast({
        title: "Trending",
        description: "Pair trending updated.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: "Trending",
        description: "Update pair trending error",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    // Add your API calls or any other logic here
    setSubmitting(false);
  };

  const initialValues: EditRuleChecker = {
    isIgnoreRuleChecker: currentEditRuleCheckPair?.isIgnoreRuleChecker
      ? RuleCheckerStatus.Enable
      : RuleCheckerStatus.Disable,
    status: currentEditRuleCheckPair?.status
      ? currentEditRuleCheckPair?.status
      : undefined,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Rule Check</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={4}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <VStack spacing={4}>
                  <Text as="p">
                    By default, all pairs will be checked by our rules every 5
                    minutes.
                  </Text>

                  <Field name="isIgnoreRuleChecker">
                    {({
                      field,
                      form,
                    }: FieldProps<EditRuleChecker["isIgnoreRuleChecker"]>) => (
                      <FormControl>
                        <FormLabel>
                          Do you want to check rules for this pair?
                        </FormLabel>
                        <RadioGroup
                          {...field}
                          id="isIgnoreRuleChecker"
                          onChange={(e) => {
                            form.setFieldValue("isIgnoreRuleChecker", e);
                          }}
                        >
                          <Stack direction="column">
                            <Text fontWeight="bold" color="green">
                              <Radio value="disable">
                                Yes, check rules for it.
                              </Radio>
                            </Text>
                            <Text fontWeight="bold" color="red">
                              <Radio value="enable">
                                No, skip it. No need to check this pair.
                              </Radio>
                            </Text>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="status">
                    {({
                      field,
                      form,
                    }: FieldProps<EditRuleChecker["status"]>) => (
                      <FormControl>
                        <FormLabel>Status</FormLabel>
                        <RadioGroup
                          {...field}
                          id="status"
                          onChange={(e) => {
                            form.setFieldValue("status", Number(e));
                          }}
                        >
                          <Stack direction="column">
                            <Text fontWeight="bold" color="green">
                              <Radio value={1}>OK</Radio>
                            </Text>
                            <Text fontWeight="bold" color="red">
                              <Radio value={2}>Suspend</Radio>
                            </Text>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Field>

                  {/* Submit Button */}
                  <Button type="submit" colorScheme="teal">
                    Update
                  </Button>
                </VStack>
              </Form>
            </Formik>
          </Box>
        </ModalBody>
        {/* <ModalFooter></ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}

export default ModalEditRuleCheck;
