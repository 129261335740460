import { get } from "utils/BaseRequest";

export const getCrawlers = async () => {
  const { data: result } = await get("/crawler");
  return result;
};

export const getRouterAnalysts = async () => {
  const { data: result } = await get("/crawler/router-analyst");
  return result;
};
