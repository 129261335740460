export enum VerifyTokenInfo {
  KYC = 'KYC',
  AUDIT = 'AUDIT',
  DOXXED = 'DOXXED',
  SAFU = 'SAFU',
  SCAM = 'SCAM',
}

export const listVerifyTokenInfo = [
  { name: 'KYC', value: VerifyTokenInfo.KYC },
  { name: 'AUDIT', value: VerifyTokenInfo.AUDIT },
  { name: 'DOXXED', value: VerifyTokenInfo.DOXXED },
  { name: 'SAFU', value: VerifyTokenInfo.SAFU },
  { name: 'SCAM', value: VerifyTokenInfo.SCAM },
];
