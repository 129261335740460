const constants = {
  DEFAULT_CHAIN_ID: Number(process.env.REACT_APP_DEFAULT_CHAIN_ID),
  TOAST_DURATION: 3000, // 3s
  GLOBAL_EVENT: {
    TOGGLE_MENU: "TOGGLE_MENU",
    HIDDEN_MENU: "HIDDEN_MENU",
    FORCE_RESOURCE: "FORCE_RESOURCE",
    WATCH_LIST: "WATCH_LIST",
  },
  STORAGE: {
    ACCESS_TOKEN: "access_token",
    PERMISSION: "permission",
  },
  USER: {
    TABS: "multichartsTabs",
    PAIRS: "multichartsPairs",
  },
  CHAINS: {
    56: "bsc",
    1: "ethereum",
  },
  REGEX_URL:
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
};

export default constants;
