import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  useDisclosure,
  useToast,
  Text,
  AlertDialogHeader,
  InputGroup,
  InputRightElement,
  Checkbox,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Paginate from "components/Pagination";
import React from "react";

import {
  getListAccountAdmin,
  deleteAccountAdmin,
  addAccountAdmin,
  updateAccountAdmin,
} from "utils/apiService";
import AccountAdminTable from "./components/AdminAccountTable";

const columns = [
  {
    Header: "Username",
    accessor: "username",
  },
  {
    Header: "Manage Token",
    accessor: "manageToken",
  },
  {
    Header: "Manage Pair",
    accessor: "managePair",
  },
  {
    Header: "Manage Dex",
    accessor: "manageDex",
  },
  {
    Header: "Manage Trending",
    accessor: "manageTrending",
  },
  {
    Header: "Manage Account",
    accessor: "manageAccount",
  },
  {
    Header: "Action",
    accessor: "_id",
  },
];

export default function ManageAdminAccount() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const cancelRefAdd = React.useRef();

  const [pageIndex, setPageIndex] = React.useState(1);
  const [showPass, setShowPass] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);
  const [currentAccount, setCurrentAccount] = React.useState({
    username: "",
    password: "",
    role: "",
    manageToken: false,
    managePair: false,
    manageDex: false,
    manageTrending: false,
    manageAccount: false,
    manageBlacklist: false,
    manageTokenInfo: false,
    manageTokenInfoRequest: false,
    manageCommunityWallet: false,
    manageDexViewScore: false,
    manageWhitelist: false,
  });

  const { data: accounts } = useQuery(["ADMIN_ACCOUNT_LIST", pageIndex], () =>
    getListAccountAdmin(pageIndex)
  );

  const onClickDel = (id: string) => {
    onOpen();
    setCurrentId(id);
  };

  const onConfirmDel = async () => {
    try {
      if (currentId) {
        await deleteAccountAdmin(currentId);
        toast({
          title: "Account.",
          description: "Account deleted.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries(["ADMIN_ACCOUNT_LIST"]);
      }
    } catch {
      toast({
        title: "Account.",
        description: "Account can't deleted.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentId(null);
    onClose();
  };

  const onClickUpdate = (acc: any) => {
    onOpenAdd();

    const curAccount = accounts?.data?.find?.(
      (item: any) => item._id === acc._id
    );

    setCurrentAccount(curAccount);
    setCurrentId(acc._id);
  };

  const onClickOpenAdd = () => {
    onOpenAdd();
    setCurrentAccount({
      username: "",
      password: "",
      role: "",
      manageToken: false,
      managePair: false,
      manageDex: false,
      manageTrending: false,
      manageAccount: false,
      manageTokenInfo: false,
      manageTokenInfoRequest: false,
      manageBlacklist: false,
      manageCommunityWallet: false,
      manageDexViewScore: false,
      manageWhitelist: false,
    });
    setCurrentId(null);
  };

  const onConfirmAddOrUpdate = async () => {
    try {
      if (currentId) {
        const { password, ...rest } = currentAccount;
        await updateAccountAdmin(currentId, {
          ...rest,
          password: password && password !== "" ? password : undefined,
        });
        toast({
          title: "Account.",
          description: "Account updated.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        await addAccountAdmin(currentAccount);
        toast({
          title: "Account.",
          description: "Account added.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
      await queryClient.invalidateQueries(["ADMIN_ACCOUNT_LIST"]);
      setCurrentId(null);
      setCurrentAccount({
        username: "",
        password: "",
        role: "",
        manageToken: false,
        managePair: false,
        manageDex: false,
        manageTrending: false,
        manageAccount: false,
        manageTokenInfo: false,
        manageTokenInfoRequest: false,
        manageBlacklist: false,
        manageCommunityWallet: true,
        manageDexViewScore: true,
        manageWhitelist: true,
      });
    } catch {
      toast({
        title: "Account.",
        description: "Account can't updated.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    onCloseAdd();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onConfirmDel} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenAdd}
        leastDestructiveRef={cancelRefAdd}
        onClose={onCloseAdd}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {currentId ? "Update" : "Add"} Account
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb="8px">Username</Text>
              <Input
                placeholder="..."
                size="md"
                mb="8px"
                value={currentAccount?.username || ""}
                readOnly={currentId ? true : false}
                onChange={(e) =>
                  setCurrentAccount({
                    ...currentAccount,
                    username: e.target.value,
                  })
                }
              />

              <InputGroup size="md" mb="8px">
                <Input
                  pr="4.5rem"
                  type={showPass ? "text" : "password"}
                  placeholder="Enter password"
                  value={currentAccount?.password || ""}
                  onChange={(e) =>
                    setCurrentAccount({
                      ...currentAccount,
                      password: e.target.value,
                    })
                  }
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageDex}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageDex: e.target.checked,
                    });
                  }}
                >
                  Manage Dex
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.managePair}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      managePair: e.target.checked,
                    });
                  }}
                >
                  Manage Pair
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageToken}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageToken: e.target.checked,
                    });
                  }}
                >
                  Manage Token
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageTrending}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageTrending: e.target.checked,
                    });
                  }}
                >
                  Manage Trending
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageTokenInfo}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageTokenInfo: e.target.checked,
                    });
                  }}
                >
                  Manage Token Info
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageAccount}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageAccount: e.target.checked,
                    });
                  }}
                >
                  Manage Account
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageTokenInfoRequest}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageTokenInfoRequest: e.target.checked,
                    });
                  }}
                >
                  Token Info Request
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageBlacklist}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageBlacklist: e.target.checked,
                    });
                  }}
                >
                  Black List
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageCommunityWallet}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageCommunityWallet: e.target.checked,
                    });
                  }}
                >
                  Community Vote
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageDexViewScore}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageDexViewScore: e.target.checked,
                    });
                  }}
                >
                  DexView Score
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  defaultChecked={currentAccount?.manageWhitelist}
                  onChange={(e) => {
                    setCurrentAccount({
                      ...currentAccount,
                      manageWhitelist: e.target.checked,
                    });
                  }}
                >
                  Manage WhiteList
                </Checkbox>
              </Box>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAdd}>
                Cancel
              </Button>
              <Button colorScheme="green" onClick={onConfirmAddOrUpdate} ml={3}>
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <AccountAdminTable
          columnsData={columns}
          tableData={accounts?.data || []}
          onClickDel={onClickDel}
          onClickUpdate={onClickUpdate}
          onOpenAdd={onClickOpenAdd}
        />

        <Paginate data={accounts} callback={setPageIndex} />
      </Box>
    </>
  );
}
