import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  useDisclosure,
  Text,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loading from "components/loading";
import Paginate from "components/Pagination";
import React from "react";
import useDebounce from "shared/hooks/useDebouce";

import {
  getListToken,
  delToken,
  updateToken,
  addToken,
  getListChain,
  updateIsHoneypotToken,
} from "utils/apiService";
import TokenTable from "./components/TokenTable";

const columns = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "ChainId",
    accessor: "chainId",
  },
  {
    Header: "Symbol",
    accessor: "symbol",
  },
  {
    Header: "Decimals",
    accessor: "decimals",
  },
  {
    Header: "Honeypot",
    accessor: "isHoneypotSetByAdmin",
  },
  {
    Header: "Action",
    accessor: "_id",
  },
];

export default function ManageToken() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const cancelRef = React.useRef();
  const cancelRefAdd = React.useRef();

  const [pageIndex, setPageIndex] = React.useState(1);
  const [currentId, setCurrentId] = React.useState(null);
  const [currentToken, setCurrentToken] = React.useState<{
    isHoneypotSetByAdmin?: "0" | "1";
  }>({
    isHoneypotSetByAdmin: undefined,
  });
  const [chain, setChain] = React.useState<string>();
  const [keyword, setKeyword] = React.useState<string>();

  const debounceValue = useDebounce(keyword, 1000);

  const {
    data: tokens,
    isLoading,
    refetch,
  } = useQuery(["TOKEN_LIST", chain, debounceValue, pageIndex], () =>
    getListToken({
      page: pageIndex,
      chain,
      keyword: debounceValue,
    })
  );

  const { data: chains } = useQuery(["CHAIN_LIST"], () =>
    getListChain(1, 1000)
  );

  const onClickDel = (id: string) => {
    onOpen();
    setCurrentId(id);
  };

  const onConfirmDel = async () => {
    try {
      if (currentId) {
        await delToken(currentId);
        toast({
          title: "Token.",
          description: "Token deleted.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        await queryClient.invalidateQueries(["TOKEN_LIST"]);
      }
    } catch {
      toast({
        title: "Token.",
        description: "Token can't deleted.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    setCurrentId(null);
    onClose();
  };

  const onClickUpdate = (token: any) => {
    onOpenAdd();

    const isHoneypotSetByAdmin =
      token.isHoneypotSetByAdmin === true
        ? "1"
        : token.isHoneypotSetByAdmin === false
        ? "0"
        : undefined;
    setCurrentToken({ isHoneypotSetByAdmin });
    setCurrentId(token._id);
  };

  const onClickOpenAdd = () => {
    onOpenAdd();
    setCurrentToken({
      isHoneypotSetByAdmin: undefined,
    });
    setCurrentId(null);
  };

  const onClickUpdateTokenHoneypot = async () => {
    try {
      if (!currentId) return;

      await updateIsHoneypotToken(currentId, currentToken);
      await refetch();
      setCurrentId(null);
      setCurrentToken({
        isHoneypotSetByAdmin: undefined,
      });
      toast({
        title: "Token.",
        description: "Token honeypot updated.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Token.",
        description: "Token can't updated.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    onCloseAdd();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onConfirmDel} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isOpenAdd}
        leastDestructiveRef={cancelRefAdd}
        onClose={onCloseAdd}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {currentId ? "Update" : "Add"} Token
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text mb="8px" fontSize="xl" fontWeight="bold" color="red">
                Is this token Honeypot?
              </Text>
              <Select
                placeholder="I don't know!"
                onChange={(e) => {
                  setCurrentToken({
                    isHoneypotSetByAdmin: e.target.value as any,
                  });
                }}
                value={currentToken?.isHoneypotSetByAdmin}
              >
                <option key={"1"} value={1}>
                  YES, it is a honeypot
                </option>
                <option key={"0"} value={0}>
                  NO, it is not a honeypot
                </option>
              </Select>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAdd}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={onClickUpdateTokenHoneypot}
                ml={3}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <TokenTable
          isLoading={isLoading}
          columnsData={columns}
          tableData={tokens?.data || []}
          onClickDel={onClickDel}
          onClickUpdate={onClickUpdate}
          onOpenAdd={onClickOpenAdd}
          chains={chains}
          onChangeChain={(v: string) => {
            setChain(v);
          }}
          onChangeKeyword={(v: string) => {
            setKeyword(v);
          }}
        />

        <Paginate data={tokens} callback={setPageIndex} />
      </Box>
    </>
  );
}
