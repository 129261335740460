import { ChainIds } from "configs/chain";
import { VerifyTokenInfo } from "configs/verifyTokenInfo";
import { get, instance } from "utils/BaseRequest";

export interface GetTokenInfoParams {
  pageIndex: number;
  pageSize: number;
  skip?: number;
  chainId?: string;
  keyword?: string;
  status?: any[];
}

export interface AddOrUpdateTokenInfoParams {
  chainId: ChainIds;
  address: string;
  owner: string;
  website: string;
  telegram: string;
  twitter: string;
  discord: string;
  github: string;
  description: string;
  validators: { url: string; type: VerifyTokenInfo }[];
}

export const getTokenInfo = async (params: GetTokenInfoParams) => {
  const result = await get("/token-info", params);
  return result;
};

export const addTokenInfo = async (params: AddOrUpdateTokenInfoParams) => {
  return await instance.post("/manager/token-info", params);
};

export const updateTokenInfo = async (
  id: string,
  params: AddOrUpdateTokenInfoParams
) => await instance.patch(`/manager/token-info/${id}`, params);

export const removeTokenInfo = async (id: string) =>
  await instance.delete(`/manager/token-info/${id}`);
