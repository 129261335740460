export enum TokenInfoRequest {
  DELETED = 0,
  ACCEPTED = 1,
  PENDING = 2,
  REJECTED = 3,
}

export const listTokenInfoRequestStatus = [
  { name: 'Deleted', value: TokenInfoRequest.DELETED },
  { name: 'Pending', value: TokenInfoRequest.PENDING },
  { name: 'Accepted', value: TokenInfoRequest.ACCEPTED },
  { name: 'Rejected', value: TokenInfoRequest.REJECTED },
];
