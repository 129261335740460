import {
  Box,
  Button,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
  Tag,
} from "@chakra-ui/react";
import { getChainNameFromChainId } from "configs/chain";
import { TokenInfoRequest } from "configs/tokenInfoRequest";
import { useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { generateTokenLink, subAddress } from "utils/helpers";
import ModalUpdateTokenInfo from "./ModalUpdate";

const columns = [
  {
    Header: "updateAt",
    accessor: "updateAt",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Chart",
    accessor: "Chart",
  },
  {
    Header: "ChainId",
    accessor: "chainId",
  },
  {
    Header: "Owner",
    accessor: "owner",
  },
  {
    Header: "Website",
    accessor: "website",
  },
  {
    Header: "Telegram",
    accessor: "telegram",
  },
  {
    Header: "Twitter",
    accessor: "twitter",
  },
  {
    Header: "Discord",
    accessor: "discord",
  },
  {
    Header: "Github",
    accessor: "github",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "UpdatedBy",
    accessor: "updatedBy",
  },
  {
    Header: "Action",
    accessor: "_id",
  },
];

function getTimestampDifference(timestamp1: number, timestamp2: number) {
  const millisecondsPerSecond = 1000;
  const secondsPerMinute = 60;
  const minutesPerHour = 60;
  const hoursPerDay = 24;

  const timeDifference = Math.abs(timestamp2 - timestamp1);
  const days = Math.floor(
    timeDifference /
      (millisecondsPerSecond * secondsPerMinute * minutesPerHour * hoursPerDay)
  );
  const hours = Math.floor(
    (timeDifference %
      (millisecondsPerSecond *
        secondsPerMinute *
        minutesPerHour *
        hoursPerDay)) /
      (millisecondsPerSecond * secondsPerMinute * minutesPerHour)
  );
  const minutes = Math.floor(
    (timeDifference %
      (millisecondsPerSecond * secondsPerMinute * minutesPerHour)) /
      (millisecondsPerSecond * secondsPerMinute)
  );

  const formattedDifference = `${days}d${hours}h${minutes}m`;
  return formattedDifference;
}

interface IProps {
  tableData: any;
}
export default function TokenInfoTable({ tableData }: IProps) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const toast = useToast();

  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();

  const [rowObj, setRowObj] = useState(null);
  const tableInstance = useTable(
    {
      columns,
      data: tableData || [],
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("white", "navy.800");

  const handleUpdate = (row: any) => {
    setRowObj(row);
    onOpenUpdate();
  };

  const handleCopy = (address: string | undefined) => {
    if (!address) return;
    navigator.clipboard.writeText(address);
    toast({
      title: "Copy successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const statusObj: any = {
    [TokenInfoRequest.DELETED]: {
      color: "gray",
      name: "Deleted",
    },
    [TokenInfoRequest.ACCEPTED]: {
      color: "green",
      name: "Accepted",
    },
    [TokenInfoRequest.PENDING]: {
      color: "orange",
      name: "Pending",
    },
    [TokenInfoRequest.REJECTED]: {
      color: "red",
      name: "Rejected",
    },
  };
  return (
    <>
      <ModalUpdateTokenInfo
        isOpen={isOpenUpdate}
        onClose={onCloseUpdate}
        currentRow={rowObj}
      />

      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  bgColor={bgColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody
          {...getTableBodyProps()}
          color={textColor}
          fontWeight={"bold"}
          fontSize={"sm"}
        >
          {page?.map((row: any, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                <Td>
                  {row?.original?.updateAt &&
                    getTimestampDifference(row?.original?.updateAt, Date.now())}
                </Td>
                <Td
                  onClick={() => handleCopy(row?.original?.address)}
                  cursor="copy"
                >
                  {subAddress(row?.original?.address)}
                </Td>
                <Td>
                  <Link
                    color="blue"
                    href={generateTokenLink(
                      row?.original?.chainId,
                      row?.original?.address
                    )}
                    isExternal={true}
                  >
                    Chart
                  </Link>
                </Td>
                <Td>
                  {getChainNameFromChainId(Number(row?.original?.chainId))}
                </Td>
                <Td
                  onClick={() => handleCopy(row?.original?.owner)}
                  cursor="copy"
                >
                  {subAddress(row?.original?.owner)}
                </Td>
                <Td>
                  {row?.original?.website ? (
                    <Link href={row?.original?.website} target={"_blank"}>
                      {row?.original?.website}
                    </Link>
                  ) : null}
                </Td>
                <Td>
                  {row?.original?.telegram ? (
                    <Link href={row?.original?.telegram} target={"_blank"}>
                      {row?.original?.telegram}
                    </Link>
                  ) : null}
                </Td>

                <Td>
                  <Text noOfLines={2}>
                    {row?.original?.twitter ? (
                      <Link href={row?.original?.twitter} target={"_blank"}>
                        {row?.original?.twitter}
                      </Link>
                    ) : null}
                  </Text>
                </Td>

                <Td>
                  <Text noOfLines={2}>
                    {row?.original?.discord ? (
                      <Link href={row?.original?.discord} target={"_blank"}>
                        {row?.original?.discord}
                      </Link>
                    ) : null}
                  </Text>
                </Td>

                <Td>
                  <Text noOfLines={2}>
                    {row?.original?.github ? (
                      <Link href={row?.original?.github} target={"_blank"}>
                        {row?.original?.github}
                      </Link>
                    ) : null}
                  </Text>
                </Td>
                <Td>
                  <Tag
                    variant={"solid"}
                    bgColor={statusObj[row?.original?.status]?.color}
                  >
                    {statusObj[row?.original?.status]?.name}
                  </Tag>
                </Td>
                <Td>
                  {row?.original?.updatedBy ? row?.original?.updatedBy : null}
                </Td>

                <Td display={"flex"} alignContent="center">
                  <Button
                    colorScheme="blue"
                    mr="5px"
                    mt="5px"
                    size="sm"
                    onClick={() => handleUpdate(row?.original)}
                  >
                    Update
                  </Button>
                  {/* <Button
                      colorScheme='red'
                      mr='5px'
                      mt='5px'
                      size='sm'
                      onClick={() => handleOpenRemove(row?.original)}
                    >
                      Delete
                    </Button> */}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
}
