/* eslint-disable */
import {
  Button,
  Flex,
  Input,
  Progress,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useMemo } from "react";
import { MdArrowDropDown } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export default function PairTable(props: {
  columnsData: any;
  tableData: any;
  onClickDel: any;
  onOpenAdd: any;
  onClickUpdate: any;
  onChangeChain: any;
  onChangeKeyword: any;
  chains: any;
}) {
  const {
    columnsData,
    tableData,
    onClickDel,
    onOpenAdd,
    onClickUpdate,
    onChangeChain,
    onChangeKeyword,
    chains,
  } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bgColor = useColorModeValue("white", "navy.800");
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      maxW="98%"
      overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
    >
      <Flex
        px="25px"
        justify="space-between"
        mb="20px"
        align="center"
        position="relative"
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Pair
        </Text>
        <Flex>
          <Input
            placeholder="Search pair address"
            mr={"2rem"}
            onChange={(e) => {
              onChangeKeyword(e.target.value);
            }}
          />

          <Select
            icon={<MdArrowDropDown />}
            placeholder="Select chain"
            bg={"whiteAlpha.200"}
            mr={"2rem"}
            onChange={(e) => {
              onChangeChain(e.target.value);
            }}
          >
            {chains?.data?.length &&
              chains?.data?.map((chain: any) => {
                return (
                  <option key={chain.id} value={chain.chainId}>
                    {chain.name}
                  </option>
                );
              })}
          </Select>
        </Flex>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  pos={{
                    base: column?.Header === "Action" ? "sticky" : "unset",
                  }}
                  zIndex={{
                    base: column?.Header === "Action" ? "banner" : "unset",
                  }}
                  right={{
                    base: column?.Header === "Action" ? "0px" : "unset",
                  }}
                  bgColor={bgColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data;

                  if (cell.column.Header === "Action") {
                    data = (
                      <>
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() => onClickDel(cell.value)}
                          mr="5px"
                          mt="5px"
                        >
                          Delete
                        </Button>
                        <Button
                          colorScheme="teal"
                          size="sm"
                          onClick={() => {
                            onClickUpdate(cell.row.values);
                          }}
                          mr="5px"
                          mt="5px"
                        >
                          Update
                        </Button>
                      </>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      pos={{
                        base:
                          cell.column?.Header === "Action" ? "sticky" : "unset",
                      }}
                      zIndex={{
                        base:
                          cell.column?.Header === "Action" ? "base" : "unset",
                      }}
                      right={{
                        base:
                          cell.column?.Header === "Action" ? "0px" : "unset",
                      }}
                      bgColor={bgColor}
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
