import queryKey from 'configs/queryKey';
import { useQuery } from '@tanstack/react-query';
import {
  getTokenInfoRequest,
  GetTokenInfoRequestParams,
} from 'utils/apiService/tokenInfoRequest';

function useTokenInfoRequest(params: GetTokenInfoRequestParams) {
  return useQuery(
    [queryKey.TOKEN_INFO_REQUEST, params],
    () => getTokenInfoRequest(params),
    {
      enabled: true,
      staleTime: 60 * 1000,
    }
  );
}

export default useTokenInfoRequest;
