/* eslint-disable */
import {
  Box,
  Flex,
  Icon,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const columns = [
  {
    Header: "Position",
    id: "position",
    accessor: (d: any, index: number) => {
      if (d.position) {
        const current = Date.now();
        const hourLeft = (d?.liveTimeTo - current) / (1000 * 60 * 60);
        return (
          <Text color="green.400">
            {d.position}
            {hourLeft ? ` (${hourLeft.toFixed(2)}h left)` : ""}
          </Text>
        );
      }

      return index + 1;
    },
  },
  {
    Header: "Rules",
    id: "Rules",
    accessor: (d: any, index: number) => {
      if (d.status === 1) return <Text color="green.400">OK</Text>;
      if (d.status === 2) {
        return (
          <Tooltip
            label={
              "Price not match with our rules. This pair will temporarily hidden on trending."
            }
            aria-label="A tooltip"
          >
            <Text cursor="pointer" noOfLines={2}>
              <Text color="red.400">Suspend</Text>
            </Text>
          </Tooltip>
        );
      }
      return <Text color="green.400">OK</Text>;
    },
  },
  {
    Header: "Base Symbol",
    accessor: "baseTokenSymbol",
  },
  {
    Header: "ChainId",
    accessor: "chainId",
  },
  {
    Header: "Pair",
    accessor: "address",
  },
  {
    Header: "Trending Score",
    id: "trendingScore",
    accessor: (d: any) => {
      return (
        <Text>{d.trendingScore ? Number(d.trendingScore).toFixed(2) : ""}</Text>
      );
    },
  },
];

export default function PairTrendingTable(props: {
  tableData: any;
  chains: any[];
  onOpenEditRuleCheck: any;
}) {
  const { tableData, chains, onOpenEditRuleCheck } = props;

  const tableInstance = useTable<{
    isIgnoreRuleChecker: boolean;
    [key: string]: any;
  }>(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgColor = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      maxW="98%"
      overflowX={{ base: "auto", sm: "scroll", lg: "auto" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Current trending
        </Text>
        <Select
          icon={<MdArrowDropDown />}
          placeholder="All"
          bg={"whiteAlpha.200"}
          mr={"2rem"}
          maxW="150px"
          onChange={(e) => {
            // onChangeChain(e.target.value);
          }}
        >
          {chains?.length &&
            chains?.map((chain: any) => {
              return (
                <option key={chain.id} value={chain.chainId}>
                  {chain.name}
                </option>
              );
            })}
        </Select>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                  pos={{
                    base: column?.Header === "Action" ? "sticky" : "unset",
                  }}
                  zIndex={{
                    base: column?.Header === "Action" ? "banner" : "unset",
                  }}
                  right={{
                    base: column?.Header === "Action" ? "0px" : "unset",
                  }}
                  bgColor={bgColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data;

                  if (cell.column.Header === "Action") {
                    data = <></>;
                  } else if (cell.column.Header === "Rules") {
                    data = (
                      <Flex>
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                        {row.original.position ? (
                          <Box
                            px={1}
                            cursor="pointer"
                            onClick={() =>
                              onOpenEditRuleCheck(cell.row.original)
                            }
                          >
                            <Icon
                              as={RiPencilFill}
                              color={
                                cell.row.original["isIgnoreRuleChecker"] ===
                                true
                                  ? "red"
                                  : "green"
                              }
                            />
                          </Box>
                        ) : null}
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                      pos={{
                        base:
                          cell.column?.Header === "Action" ? "sticky" : "unset",
                      }}
                      zIndex={{
                        base:
                          cell.column?.Header === "Action" ? "base" : "unset",
                      }}
                      right={{
                        base:
                          cell.column?.Header === "Action" ? "0px" : "unset",
                      }}
                      bgColor={bgColor}
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
