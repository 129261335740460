import queryKey from "configs/queryKey";
import { useQuery } from "@tanstack/react-query";
import { getCrawlers } from "utils/apiService/Crawler";

function useCrawlers() {
  return useQuery([queryKey.CRAWLERS], () => getCrawlers(), {
    staleTime: 2000,
    refetchInterval: 2000,
  });
}

export default useCrawlers;
