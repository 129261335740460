import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import queryKey from "configs/queryKey";
import { removeBlacklist } from "utils/apiService/Blacklist";

interface IProps {
  onClose: () => void;
  id: string;
}
const useRemoveBlacklist = ({ onClose, id }: IProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(async () => await removeBlacklist(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.BLACK_LIST]);
      onClose();
      toast({
        title: "Blacklist",
        description: "Remove a token successful",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: async (errors: any) => {
      const devMessage = errors?.response?.data?.devMessage || [];
      const errorMessage = errors?.response?.data?.errorMessage || "";
      const description = errorMessage + ":" + devMessage?.join(", ") || "";

      toast({
        title: "Blacklist.",
        description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
};

export default useRemoveBlacklist;
