import { post, get, del, patch } from "utils/BaseRequest";

export const getListDex = async (
  payload: { chain?: string, page?: number, limit?: number, keyword?: string },
  
) => {
  const page = payload.page || 1;
  const limit = payload.limit || 10;

  const result = await get("/dex", {
    ...payload,
    page,
    limit,
  });
  return result;
};

export const delDex = async (id: string) => {
  const { data: result } = await del(`/dex/${id}`);
  return result;
};

export const addDex = async (data: any) => {
  const { data: result } = await post("/dex", data);
  return result;
};

export const updateDex = async (id: string, data: any) => {
  const { data: result } = await patch(`/dex/${id}`, data);
  return result;
};
