import { Icon } from "@chakra-ui/react";
import {
  MdExtension,
  MdHome,
  MdLeaderboard,
  MdLock,
  MdOutlinePersonalInjury,
  MdOutlineSettingsInputComponent,
  MdOutlineTimeline,
  MdOutlineGeneratingTokens,
  MdCheckCircleOutline,
} from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";

// Admin Imports
import Account from "views/admin/account";
import Dex from "views/admin/dex";
import Pair from "views/admin/pair";
import PairTrending from "views/admin/pair-trending";
import Blacklist from "views/admin/blackList";
import TokenInfo from "views/admin/tokenInfo";
import Token from "views/admin/token";
import SignInCentered from "views/auth/signIn";
import TokenInfoRequest from "views/admin/tokenInfoRequest";
import Crawler from "views/admin/crawler";
import CommunityVote from "views/admin/community-wallet";
import DexViewScore from "views/admin/dexview-score";
import WhiteList from "views/admin/whiteList";
import { FaRobot } from "react-icons/fa";
import SupportBot from "views/admin/support-bot";

const permission = JSON.parse(localStorage.getItem("permission") || "{}");

const routes = [
  {
    name: "Crawler",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Crawler,
    isPrivate: true,
    permission: true,
  },
  {
    name: "Dex",
    layout: "/admin",
    path: "/dex",
    icon: (
      <Icon as={MdOutlineTimeline} width="20px" height="20px" color="inherit" />
    ),
    component: Dex,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageDex),
  },
  {
    name: "White List Router",
    layout: "/admin",
    path: "/whitelist",
    icon: (
      <Icon
        as={MdCheckCircleOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: WhiteList,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageWhitelist),
  },
  // {
  //   name: "Crawler",
  //   layout: "/admin",
  //   path: "/crawler",
  //   icon: <Icon as={AiFillRobot} width="20px" height="20px" color="inherit" />,
  //   component: Crawler,
  //   secondary: true,
  //   isPrivate: true,
  //   permission: Boolean(permission?.manageDex),
  // },
  {
    name: "Token",
    layout: "/admin",
    path: "/token",
    icon: <Icon as={MdExtension} width="20px" height="20px" color="inherit" />,
    component: Token,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageToken),
  },
  {
    name: "Pair",
    layout: "/admin",
    path: "/pair",
    icon: (
      <Icon
        as={MdOutlineSettingsInputComponent}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Pair,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.managePair),
  },
  {
    name: "Blacklist",
    layout: "/admin",
    path: "/blacklist",
    icon: <Icon as={BiBlock} width="20px" height="20px" color="inherit" />,
    component: Blacklist,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageBlacklist),
  },
  {
    name: "Token info",
    layout: "/admin",
    path: "/info",
    icon: (
      <Icon
        as={AiOutlineInfoCircle}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: TokenInfo,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageTokenInfo),
  },
  {
    name: "Token info request",
    layout: "/admin",
    path: "/request-token-info",
    icon: (
      <Icon
        as={MdOutlineGeneratingTokens}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: TokenInfoRequest,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageTokenInfoRequest),
  },
  {
    name: "Trending",
    layout: "/admin",
    path: "/trending",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: PairTrending,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageTrending),
  },
  {
    name: "Community wallet",
    layout: "/admin",
    path: "/community-wallet",
    icon: (
      <Icon
        as={MdOutlinePersonalInjury}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: CommunityVote,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageCommunityWallet),
  },
  {
    name: "DexView score",
    layout: "/admin",
    path: "/score",
    icon: (
      <Icon
        as={MdOutlinePersonalInjury}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: DexViewScore,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageDexViewScore),
  },
  {
    name: "Admin Account",
    layout: "/admin",
    path: "/account",
    icon: (
      <Icon
        as={MdOutlinePersonalInjury}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Account,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageAccount),
  },
  {
    name: "Support bot",
    layout: "/admin",
    path: "/support-bot",
    icon: <Icon as={FaRobot} width="20px" height="20px" color="inherit" />,
    component: SupportBot,
    secondary: true,
    isPrivate: true,
    permission: Boolean(permission?.manageAccount),
  },
  // {
  // 	name: 'Manage NFT',
  // 	layout: '/admin',
  // 	path: '/nft-marketplace',
  // 	icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
  // 	component: NFTMarketplace,
  // 	secondary: true
  // },
  // {
  // 	name: 'Data Tables',
  // 	layout: '/admin',
  // 	icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  // 	path: '/data-tables',
  // 	component: DataTables
  // },
  // {
  // 	name: 'Profile',
  // 	layout: '/admin',
  // 	path: '/profile',
  // 	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  // 	component: Profile
  // },

  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    isPrivate: false,
  },
];

const finalRoutes = routes.filter((route) => {
  if (route.isPrivate) {
    return route?.permission;
  }
  return true;
});

export default finalRoutes;
